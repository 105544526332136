<!-- ============================================================ -->
<template>
  <section>
    <button class="button is-primary" @click="openGroupsModal">
      Add to group
    </button>
    <b-modal
      :active.sync="showGroups"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Instance groups</p>
        </header>
        <div class="card-content">
          <div
            v-for="instancegroup in instanceGroupList"
            v-bind:key="instancegroup.id"
            class="block"
          >
            <div class="box">
              {{ instancegroup.name }}
              <button
                class="button is-primary"
                @click="addToGroup(instancegroup.id)"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<!-- ============================================================ -->
<script>
'use strict';
import gql from 'graphql-tag';
import instanceGroupListByInstanceParamsMarginQuery from '../../graphql/queries/gekko/instanceGroupListByInstanceParamsMargin.gql';
import instanceGroupListByInstanceParamsSimpleQuery from '../../graphql/queries/gekko/instanceGroupListByInstanceParamsSimple.gql';
import addGekkoInstanceToGroupMutation from '../../graphql/queries/gekko/addGekkoInstanceToGroup.gql';

export default {
  name: 'AddToGroup',
  props: {
    instanceId: {
      type: Number,
      default: null,
    },
    marginProductId: {
      type: Number,
      default: null,
    },
    baseProductId: {
      type: Number,
      default: null,
    },
    quoteProductId: {
      type: Number,
      default: null,
    },
    exchangeId: {
      type: Number,
      default: null,
    },
    candleSize: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      showGroups: false,
    };
  },

  //
  mounted() {},

  //
  created() {
    this.demandLogin();
  },
  apollo: {
    instanceGroupList() {
      //
      return {
        query() {
          if (this.marginProductId) {
            return gql`
              ${instanceGroupListByInstanceParamsMarginQuery}
            `;
          }

          return gql`
            ${instanceGroupListByInstanceParamsSimpleQuery}
          `;
        },
        update: (data) => data.gekkoInstanceGroups,
        variables() {
          const variables = {
            exchangeId: this.exchangeId,
            candleSize: this.candleSize,
          };

          if (this.marginProductId) {
            variables.marginProductId = this.marginProductId;
          } else {
            variables.baseProductId = this.baseProductId;
            variables.quoteProductId = this.quoteProductId;
          }

          return variables;
        },
        skip() {
          return !this.exchangeId || !this.candleSize;
        },
      };
    },
  },
  methods: {
    openGroupsModal() {
      this.$apollo.queries.instanceGroupList.refetch();
      this.showGroups = true;
    },
    addToGroup(groupId) {
      const variables = {
        input: {
          instanceId: this.instanceId,
          groupId: groupId,
        },
      };

      this.$apollo
        .mutate({
          mutation: addGekkoInstanceToGroupMutation,
          variables,
          update: (store, { data: { addGekkoInstanceToGroup } }) => {
            this.showGroups = false;
            this.$emit('instanceAddedToGroup', addGekkoInstanceToGroup);
          },
        })
        .catch((error) => {
          console.error('GekkoInstanceGroupManagement.vue: addToGroup', error);

          this.$buefy.toast.open({
            message: 'Error adding to group',
            type: 'is-danger',
          });
        });
    },
  },
};
</script>
