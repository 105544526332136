//===================================================================
'use strict';
//===================================================================

import moment from 'moment';

//===================================================================

export default {
    splitByQuarter(labels, data, evaluationDates, params)
    {
        let k = 0;
        let datasets = [];

        labels.forEach( (date, i) =>
        {
            const today = moment( labels[i] );
            const next = moment( evaluationDates[k] );

            if (!datasets[k]) datasets[k] = [];

            if (today.isSameOrAfter(next) ) {
                k++;
                datasets[k] = [];

                for (let x = 0; x < i; x++)
                {
                    datasets[k].push(null);
                }
            }

            datasets[k].push(data[i]);
        });
        let i = 0;
        datasets = datasets.filter(d => d)
        .map(d =>
        {
            i++;
            return {
                label: params.label ? `${params.label} Q${i}` : 'Unknown',
                borderColor: params.borderColor || 'red',
                fill: params.fill || false,
                data: d,
            };
        });

        return datasets;
    },
    //
    setDistributionChartColors(chart, distributionChartColors)
    {
        const available = ['#f79520', '#4a277a', '#d91b5d', '#fbb133', '#702b91', '#ed1f7c'];
        let backgroundColor = [];
        let i = 0;

        chart.chartData.forEach(item =>
        {
            const key = chart.chartType==='currency' ? 'code' : 'name';

            if (i===available.length ) i = 0;

            if (!distributionChartColors[chart.chartType][item[key]]) {
                if (item[key]==='EUR' || item[key]==='USD') {
                    distributionChartColors[chart.chartType][item[key]] = '#008001';
                } else {
                    distributionChartColors[chart.chartType][item[key]] = available[i];
                    i++;
                }
            }
            backgroundColor.push(distributionChartColors[chart.chartType][item[key]]);
        });

        return { backgroundColor, distributionChartColors };
    },
};
