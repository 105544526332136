<!-- ============================================================ -->
<template>
  <main>
    <page-title>Log In</page-title>

    <b-field label="Username"
      ><b-input v-model="credentials.username"></b-input
    ></b-field>
    <b-field label="Password"
      ><b-input
        v-model="credentials.password"
        type="password"
        @keyup.enter.native="loginUser()"
      ></b-input
    ></b-field>
    <button class="button is-primary is-default" default @click="loginUser()">
      Log in
    </button>
    <!--p>
            <router-link to="/password_reset">Forgot password?</router-link>
        </p-->
  </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';

import auth from '../auth';

export default {
  data() {
    return {
      credentials: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    loginUser() {
      const credentials = {
        username: this.credentials.username,
        password: this.credentials.password,
      };

      auth
        .login(this, credentials, this.$route.query.return || 'dashboard')
        .catch((error) => {
          this.$buefy.toast.open({
            message: error,
            type: 'is-danger',
          });
        });
    },
  },
  created() {
    if (auth.user.authenticated) this.$router.push('/dashboard'); // already logged in, nothing to do here
  },
};
</script>
