<!-- ============================================================ -->
<template>
  <main>
    <div style="position: relative;">
      <h1 class="title is-4">
        Performance
      </h1>
      <h1 class="subtitle">
        Portfolio performance vs index
      </h1>

      <b-field>
        <b-radio-button
          v-model="selectedIndexRange"
          native-value="months_2"
          type="is-info"
        >
          <span>2 months</span>
        </b-radio-button>
        <b-radio-button
          v-model="selectedIndexRange"
          native-value="quarter"
          type="is-info"
        >
          <span>Quarter</span>
        </b-radio-button>
        <b-radio-button
          v-model="selectedIndexRange"
          native-value="months_6"
          type="is-info"
        >
          <span>6 months</span>
        </b-radio-button>
        <b-radio-button
          v-model="selectedIndexRange"
          native-value="months_12"
          type="is-info"
        >
          <span>Year</span>
        </b-radio-button>
      </b-field>

      <b-field>
        <b-radio-button disabled type="is-light">
          <span>Load filter:</span>
        </b-radio-button>
        <b-radio-button
          :key="0"
          v-model="selectedFilter"
          :native-value="0"
          type="is-warning"
        >
          <span>No filter</span>
        </b-radio-button>
        <template v-for="filter in filters">
          <b-radio-button
            :key="filter.id"
            v-model="selectedFilter"
            :native-value="filter.id"
            type="is-primary"
          >
            <span>{{ filter.name }}</span>
          </b-radio-button>
        </template>
      </b-field>

      <br />
      <h1 class="subtitle">
        Percent change vs start of quarter
      </h1>
      <index-chart
        :chart-data="indexPctChartData"
        :options="indexPctChartOptions"
        :width="800"
        :height="200"
      />

      <br />
      <h1 class="subtitle">
        Total in USD
      </h1>
      <index-chart
        :chart-data="indexUsdChartData"
        :options="indexUsdChartOptions"
        :width="800"
        :height="200"
      />

      <b-loading :is-full-page="false" :active.sync="isLoadingIndex" />
    </div>

    <hr />

    <h1 class="title is-4">
      Portfolio changes
    </h1>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-field label="From">
          <b-datepicker
            v-model="compareDateOne"
            :min-date="compareDateMin"
            :max-date="compareDateMax"
            :first-day-of-week="1"
            placeholder="First date"
            icon="calendar"
          >
            <button
              class="button"
              @click="compareDateOne = new Date($moment().subtract(1, 'month'))"
            >
              <b-icon icon="calendar-month" />
              <span>1 month ago</span>
            </button>

            <button
              class="button"
              @click="compareDateOne = new Date($moment().subtract(1, 'week'))"
            >
              <b-icon icon="calendar-week" />
              <span>1 week ago</span>
            </button>
            <button
              class="button"
              @click="compareDateOne = new Date($moment().subtract(1, 'day'))"
            >
              <b-icon icon="calendar-outline" />
              <span>Yesterday</span>
            </button>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="To">
          <b-datepicker
            v-model="compareDateTwo"
            :min-date="compareDateMin"
            :max-date="compareDateMax"
            :first-day-of-week="1"
            placeholder="Second date"
            icon="calendar-today"
          >
            <button
              class="button"
              @click="compareDateTwo = new Date($moment().subtract(1, 'day'))"
            >
              <b-icon icon="calendar-outline" />
              <span>Yesterday</span>
            </button>

            <button
              class="button is-primary is-outlined"
              @click="compareDateTwo = new Date()"
            >
              <b-icon icon="calendar-today" />
              <span>Today</span>
            </button>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-half" />
    </div>

    <div class="columns">
      <div class="column is-two-thirds" style="position: relative;">
        <b-field>
          <b-radio-button
            v-model="compareSortBy"
            native-value="price_usd"
            type="is-info"
          >
            <span>Biggest price changes</span>
          </b-radio-button>
          <b-radio-button
            v-model="compareSortBy"
            native-value="value_usd"
            type="is-info"
          >
            <span>Biggest value changes</span>
          </b-radio-button>
          <b-radio-button
            v-model="compareSortBy"
            native-value="amount"
            type="is-info"
          >
            <span>Biggest amount changes</span>
          </b-radio-button>
        </b-field>

        <div class="field">
          <b-switch v-model="compareRelative">
            Relative changes
          </b-switch>
        </div>

        <div class="field">
          <b-button type="is-info" outlined @click="showChanges">
            Show changes
          </b-button>
        </div>

        <div
          v-if="priceChanges.length"
          class="columns is-gapless"
          style="margin-bottom: 8px;"
        >
          <div class="column is-one-third"><strong>Price</strong> (USD)</div>
          <div class="column is-one-third"><strong>Value</strong> (USD)</div>
          <div class="column is-one-third">
            <strong>Amount</strong>
          </div>
        </div>

        <template v-for="change in priceChanges">
          <coin-change :key="change.code" :change="change" />
        </template>

        <b-loading :is-full-page="false" :active.sync="isLoadingChanges" />
      </div>

      <div class="column is-one-third">
        <div style="position: relative; margin-bottom: 1.5rem;">
          <h1 class="subtitle" style="margin-bottom: 0;">
            <b-icon icon="calendar-today" />
            <span>Latest</span>
          </h1>
          <div class="columns is-gapless">
            <div class="column is-half">
              <h1 class="subtitle">
                Currencies (USD)
              </h1>
              <currency-chart
                :chart-data="currency_todayChartData"
                :options="distributionChartOptions"
              />
            </div>
            <div class="column is-half">
              <h1 class="subtitle">
                Exchanges (USD)
              </h1>
              <exchange-chart
                :chart-data="exchange_todayChartData"
                :options="distributionChartOptions"
              />
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingTodayDistributionCharts"
          />
        </div>

        <div style="position: relative; min-height: 100px;">
          <template v-if="currency_fromChartData">
            <h1 class="subtitle" style="margin-bottom: 0;">
              <b-icon icon="calendar" />
              <span>From</span>
            </h1>
            <div class="columns is-gapless">
              <div class="column is-half">
                <h1 class="subtitle">
                  Currencies (USD)
                </h1>
                <currency-chart
                  :chart-data="currency_fromChartData"
                  :options="distributionChartOptions"
                />
              </div>
              <div class="column is-half">
                <h1 class="subtitle">
                  Exchanges (USD)
                </h1>
                <exchange-chart
                  :chart-data="exchange_fromChartData"
                  :options="distributionChartOptions"
                />
              </div>
            </div>

            <h1 class="subtitle" style="margin-bottom: 0;">
              <b-icon icon="calendar-today" />
              <span>To</span>
            </h1>
            <div class="columns is-gapless">
              <div class="column is-half">
                <h1 class="subtitle">
                  Currencies (USD)
                </h1>
                <currency-chart
                  :chart-data="currency_toChartData"
                  :options="distributionChartOptions"
                />
              </div>
              <div class="column is-half">
                <h1 class="subtitle">
                  Exchanges (USD)
                </h1>
                <exchange-chart
                  :chart-data="exchange_toChartData"
                  :options="distributionChartOptions"
                />
              </div>
            </div>
          </template>

          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingDistributionCharts"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';

import gql from 'graphql-tag';
//
import perfUtils from '../../utilities/performance';
//
import indexChartDataMutation from '../../graphql/queries/performance/indexChartData.gql';
import priceChangesMutation from '../../graphql/queries/performance/priceChanges.gql';
import distributionChartsMutation from '../../graphql/queries/performance/distributionCharts.gql';
import filterListQuery from '../../graphql/queries/performance/filterList.gql';
//
import IndexChart from './IndexChart.js';
import CurrencyChart from './CurrencyChart.js';
import ExchangeChart from './ExchangeChart.js';
import CoinChange from './CoinChange.vue';
import auth from '../../auth';
//
export default {
  name: 'Performance',
  //
  components: {
    IndexChart,
    CurrencyChart,
    ExchangeChart,
    CoinChange,
  },
  //
  data() {
    return {
      isLoadingIndex: false,
      isLoadingChanges: false,
      isLoadingDistributionCharts: false,
      isLoadingTodayDistributionCharts: false,
      //
      selectedIndexRange: 'months_2',
      //
      indexPctChartData: null,
      indexUsdChartData: null,
      //
      currency_todayChartData: null,
      exchange_todayChartData: null,
      currency_fromChartData: null,
      exchange_fromChartData: null,
      currency_toChartData: null,
      exchange_toChartData: null,
      //
      distributionChartColors: {
        currency: [],
        exchange: [],
      },
      //
      distributionChartDateOne: null,
      distributionChartDateTwo: null,
      //
      indexPctChartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,

                callback: (value, index, values) => value + '%',
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (item) =>
              this.$options.filters.formatAmount(item.value, 4) + '%',
          },
        },
      },
      indexUsdChartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value, index, values) =>
                  this.$options.filters.formatCurrency(value),
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (item) => this.$options.filters.formatCurrency(item.value),
          },
        },
      },
      //
      distributionChartOptions: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) =>
              `${
                data.labels[tooltipItem.index]
              }: ${this.$options.filters.formatCurrency(
                data.datasets[0].data[tooltipItem.index]
              )}`,
          },
        },
      },
      //
      compareDateOne: new Date(this.$moment().subtract(7, 'day')),
      compareDateTwo: new Date(),
      compareDateMin: new Date('2018-01-01'),
      compareDateMax: new Date(),
      compareSortBy: 'value_usd',
      compareRelative: false,
      priceChanges: [],
      //
      filters: [],
      selectedFilter: 0,
    };
  },
  //
  apollo: {
    filters() {
      //
      return {
        query: gql`
          ${filterListQuery}
        `,
        update: null,
        //result(result)
        //{
        //    console.log(result);
        //},
      };
    },
  },
  //
  watch: {
    selectedIndexRange() {
      this.updateIndexChart();
    },
    selectedFilter() {
      this.updateIndexChart();
    },
  },
  //
  created() {
    this.demandLogin();
  },
  //
  mounted() {
    if (auth.user.authenticated) {
      this.updateIndexChart();
      this.updateDistributionCharts(true);
    }
  },
  //
  methods: {
    updateIndexChart() {
      this.isLoadingIndex = true;

      const variables = {
        input: {
          range: this.selectedIndexRange,
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      // clear the data for a reload animation (instead of adding or removing data from existing chart)
      this.indexPctChartData = {
        labels: [],
        datasets: [],
      };
      this.indexUsdChartData = {
        labels: [],
        datasets: [],
      };

      this.$apollo
        .mutate({
          mutation: indexChartDataMutation,
          variables,
          update: (store, { data: { indexChartForRange } }) => {
            console.log(
              'Performance LOG 1 ',
              JSON.stringify(indexChartForRange)
            );

            const indexLabels = indexChartForRange.days.map(
              (dayData) => dayData.date
            );

            const indexPctData = indexChartForRange.days.map(
              (dayData) => dayData.indexPct
            );
            const portfolioPctData = indexChartForRange.days.map(
              (dayData) => dayData.portfolioPct
            );

            const indexUsdData = indexChartForRange.days.map(
              (dayData) => dayData.indexUsd
            );
            const portfolioUsdData = indexChartForRange.days.map(
              (dayData) => dayData.portfolioUsd
            );

            const evaluationDates = indexChartForRange.evaluationDates;
            let evaluationUsd = indexChartForRange.initialEvaluationUsd;

            console.log('Performance LOG 2');

            //

            const datasets = perfUtils
              .splitByQuarter(indexLabels, indexPctData, evaluationDates, {
                label: 'Index',
                borderColor: '#f79520',
                fill: false,
              })
              .concat(
                perfUtils.splitByQuarter(
                  indexLabels,
                  portfolioPctData,
                  evaluationDates,
                  { label: 'Portfolio', borderColor: '#4a277a', fill: true }
                )
              );

            this.indexPctChartData = {
              labels: indexLabels,
              datasets,
            };

            console.log('Performance LOG 3');

            //

            let evaluationData = [];
            let j = 0;

            for (let i = 0; i < indexUsdData.length; i++) {
              const today = this.$moment(indexLabels[i]);
              const next = this.$moment(evaluationDates[j]);

              if (today.isSameOrAfter(next)) {
                evaluationUsd = indexUsdData[i];
                j++;
              }

              evaluationData.push(evaluationUsd);
            }

            // we want the chart to start at around the half of the min value on it
            const minValue = Math.min(
              ...indexUsdData.concat(portfolioUsdData).concat(evaluationData)
            );
            let round = 1;
            let i = 0;
            while (i < Math.round(minValue).toString().length - 1) {
              round = round * 10;
              i++;
            }
            this.indexUsdChartOptions.scales.yAxes[0].ticks.min =
              Math.round(minValue / (2 * round)) * round;

            this.indexUsdChartData = {
              labels: indexLabels,
              datasets: [
                {
                  label: 'Index USD value',
                  borderColor: '#fbb133',
                  fill: false,
                  data: indexUsdData,
                },
                {
                  label: 'Portfolio USD value',
                  borderColor: '#702b91',
                  data: portfolioUsdData,
                },
                {
                  label: 'Value at quarter',
                  borderColor: '#ed1f7c',
                  fill: false,
                  lineTension: 0,
                  pointStyle: 'rect',
                  data: evaluationData,
                },
              ],
            };

            this.isLoadingIndex = false;
            console.log('Performance LOG 4');
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateIndexChart', error);
          this.isLoadingIndex = false;
          this.$buefy.toast.open({
            message: 'Error updating chart',
            type: 'is-danger',
          });
        });
    },
    //
    showChanges() {
      this.updateChanges();
      if (
        this.distributionChartDateOne !== this.compareDateOne ||
        this.distributionChartDateTwo !== this.compareDateTwo
      ) {
        this.updateDistributionCharts();
      }
    },
    updateChanges() {
      this.isLoadingChanges = true;

      const variables = {
        input: {
          from: this.$moment(this.compareDateOne).format('YYYY-MM-DD'),
          to: this.$moment(this.compareDateTwo).format('YYYY-MM-DD'),
          sortBy: this.compareSortBy,
          sortRelative: this.compareRelative,
        },
      };

      this.$apollo
        .mutate({
          mutation: priceChangesMutation,
          variables,
          update: (store, { data: { priceChanges } }) => {
            this.priceChanges = priceChanges;
            //
            this.isLoadingChanges = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateChanges', error);
          this.isLoadingChanges = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },
    //
    updateDistributionCharts(onlyNow = false) {
      if (onlyNow) this.isLoadingTodayDistributionCharts = true;
      else this.isLoadingDistributionCharts = true;

      if (!onlyNow) {
        this.distributionChartDateOne = this.compareDateOne;
        this.distributionChartDateTwo = this.compareDateTwo;
      }

      const dates = onlyNow
        ? [this.$moment()]
        : [
            this.$moment(this.compareDateOne).format('YYYY-MM-DD'),
            this.$moment(this.compareDateTwo).format('YYYY-MM-DD'),
          ];

      const variables = {
        input: {
          dates,
        },
      };

      this.$apollo
        .mutate({
          mutation: distributionChartsMutation,
          variables,
          update: (store, { data: { distributionCharts } }) => {
            distributionCharts.forEach((chart) => {
              let chartDate = 'today';
              if (!onlyNow) {
                chartDate =
                  chart.date ==
                  this.$moment(this.compareDateOne).format('YYYY-MM-DD')
                    ? 'from'
                    : 'to';
              }

              const labels = chart.chartData.map((item) => item.name);
              const data = chart.chartData.map((item) =>
                item.usdvalue.toFixed(2)
              );

              const chartColorsResult = perfUtils.setDistributionChartColors(
                chart,
                this.distributionChartColors
              );
              const backgroundColor = chartColorsResult.backgroundColor;
              this.distributionChartColors =
                chartColorsResult.distributionChartColors;

              this[`${chart.chartType}_${chartDate}ChartData`] = {
                labels,
                datasets: [
                  {
                    backgroundColor,
                    data,
                  },
                ],
              };
            });

            if (onlyNow) this.isLoadingTodayDistributionCharts = false;
            else this.isLoadingDistributionCharts = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateDistributionCharts', error);
          if (onlyNow) this.isLoadingTodayDistributionCharts = false;
          else this.isLoadingDistributionCharts = false;
          this.$buefy.toast.open({
            message: 'Error updating chart',
            type: 'is-danger',
          });
        });
    },
  },
};
</script>
