<!-- ============================================================ -->
<template>
  <section>
    <b-table :data="instanceList">
      <template slot-scope="props">
        <b-table-column field="guid" label="ID" width="40">
          {{ props.row.guid }}
        </b-table-column>
        <b-table-column field="candleSize" label="TIME" width="40">
          {{ props.row.candleSize }}M
        </b-table-column>
        <b-table-column label="PAIR" width="40">
          {{ getPair(props.row) }}
        </b-table-column>
        <b-table-column
          field="running"
          label="STAT"
          width="40"
          :cell-class="(props.row.running ? 'has-text-success' : 'has-text-danger')"
        >
          {{ props.row.running ? 'RUN' : 'STO' }}
        </b-table-column>
        <b-table-column field="startTime" label="DUR" width="40">
          {{ getDuration(props.row.startTime, props.row.stateTimestamp) }}
        </b-table-column>
        <b-table-column field="strategyName" label="STRAT" width="40">
          {{ props.row.strategyName }}
        </b-table-column>
        <b-table-column field="unrealizedPnL" label="UN-PnL" width="40">
          {{ props.row.unrealizedPnLPerc | formatAmount(4) }}% ({{
            props.row.unrealizedPnL | formatAmount(4)
          }})
        </b-table-column>
        <b-table-column
          field="initialBalance"
          label="INIT/CURR BALANCE"
          width="40"
        >
          {{ props.row.initialBalance | formatCurrency() }} /
          {{ props.row.currentBalance | formatCurrency() }}
        </b-table-column>
        <b-table-column field="currentProfit" label="PROFIT" width="40">
          {{ props.row.currentProfitPerc | formatAmount(4) }} % ({{
            props.row.currentProfit | formatCurrency()
          }})
        </b-table-column>
        <b-table-column field="trades" label="TRADES/POS" width="40">
          {{ props.row.trades }} / {{ props.row.openPositions }}
        </b-table-column>
        <b-table-column label="STRAT/ACC EXPOSURE" width="40">
          {{ props.row.exposureSide }}
        </b-table-column>
        <b-table-column label="GROUP" width="40">
          <add-to-group
            v-if="!props.row.gekkoInstanceGroupId"
            @instanceAddedToGroup="getInstanceList"
            v-bind:instance-id="props.row.instanceId"
            v-bind:margin-product-id="props.row.marginProductId"
            v-bind:base-product-id="props.row.baseProductId"
            v-bind:quote-product-id="props.row.quoteProductId"
            v-bind:exchange-id="props.row.exchangeId"
            v-bind:candle-size="props.row.candleSize"
          />
          <span v-if="props.row.gekkoInstanceGroupName">
            {{ props.row.gekkoInstanceGroupName }}
            <b-tooltip
              label="Remove from group"
              position="is-right"
              type="is-info"
            >
              <b-button
                size="is-small"
                type="is-danger"
                icon-right="delete"
                @click="removeFromGroup(props.row.instanceId)"
              ></b-button>
            </b-tooltip>
          </span>
        </b-table-column>
      </template>
    </b-table>
    <b-loading :is-full-page="false" :active.sync="isLoadingInstanceList" />
  </section>
</template>

<!-- ============================================================ -->
<script>
'use strict';
import instanceListMutation from '../../graphql/queries/gekko/instanceList.gql';
import removeGekkoInstanceFromGroupMutation from '../../graphql/queries/gekko/removeGekkoInstanceFromGroup.gql';
import instanceStateUpdateSubscription from '../../graphql/queries/gekko/instanceStateUpdateSubscription.gql';
//
import AddToGroup from './AddToGroup.vue';

export default {
  name: 'GekkoInstanceList',
  components: {
    AddToGroup,
  },
  props: {
    isRunning: Boolean,
    isAssignedToGroup: Boolean,
  },
  //
  data() {
    return {
      instanceList: [],
      isLoadingInstanceList: false,
    };
  },
  methods: {
    getPair(record) {
      if (record.marginBaseSymbol && record.marginQuoteSymbol) {
        return `${record.marginBaseSymbol}${record.marginQuoteSymbol}`;
      }

      if (record.simpleBaseSymbol && record.simpleQuoteSymbol) {
        return `${record.simpleBaseSymbol}${record.simpleQuoteSymbol}`;
      }

      if (record.baseSymbol && record.quoteSymbol) {
        return `${record.baseSymbol}${record.quoteSymbol}`;
      }
    },

    getDuration(startTime, stateTimestamp) {
      const start = this.$moment(startTime);
      const to = this.$moment(stateTimestamp);
      return this.$moment.duration(to.diff(start)).humanize();
    },

    getInstanceList() {
      this.isLoadingInstanceList = true;
      this.exchangeSearchResult = [];
      const variables = {
        input: {
          isRunning: this.isRunning,
          isAssignedToGroup: this.isAssignedToGroup,
        },
      };

      this.$apollo
        .mutate({
          mutation: instanceListMutation,
          variables,
          update: (store, { data: { gekkoInstances } }) => {
            this.instanceList = gekkoInstances;
            //
            this.isLoadingInstanceList = false;

            this.subscribeToStateUpdate();
          },
        })
        .catch((error) => {
          console.error('GekkoInstanceList.vue: getInstanceList', error);
          this.isLoadingInstanceList = false;
          this.$buefy.toast.open({
            message: 'Error getting data',
            type: 'is-danger',
          });
        });
    },

    removeFromGroup(instanceId) {
      const variables = {
        input: {
          instanceId,
        },
      };

      this.$apollo
        .mutate({
          mutation: removeGekkoInstanceFromGroupMutation,
          variables,
          update: () => {
            this.getInstanceList();
          },
        })
        .catch((error) => {
          console.error('GekkoInstanceList.vue: removeFromGroup', error);

          this.$buefy.toast.open({
            message: 'Error getting data',
            type: 'is-danger',
          });
        });
    },

    subscribeToStateUpdate() {
      const that = this;
      this.$apollo
        .subscribe({
          query: instanceStateUpdateSubscription,
          variables: {},
        })
        .subscribe({
          next(data) {
            const newState = data.data.stateUpdated;
            let updated = false;
            for (let index = 0; index < that.instanceList.length; index++) {
              const instance = that.instanceList[index];
              if (instance.instanceId == newState.instanceId) {
                instance.running = newState.running;
                instance.stateTimestamp = newState.stateTimestamp;
                instance.unrealizedPnL = newState.unrealizedPnL;
                instance.unrealizedPnLPerc = newState.unrealizedPnLPerc;
                instance.currentBalance = newState.currentBalance;
                instance.currentProfit = newState.currentProfit;
                instance.currentProfitPerc = newState.currentProfitPerc;
                instance.trades = newState.trades;
                instance.exposureSide = newState.exposureSide;

                updated = true;
              }
            }

            if (!updated) {
              const newInstance = {
                instanceId: newState.instanceId,
                gekkoInstanceGroupId: newState.gekkoInstanceGroupId,
                marginProductId: newState.marginProductId,
                baseProductId: newState.baseProductId,
                quoteProductId: newState.quoteProductId,
                exchangeId: newState.exchangeId,
                guid: newState.guid,
                candleSize: newState.candleSize,
                strategyName: newState.strategyName,
                baseSymbol: newState.baseSymbol,
                quoteSymbol: newState.quoteSymbol,
                startTime: newState.startTime,
                running: newState.running,
                unrealizedPnL: newState.unrealizedPnL,
                unrealizedPnLPerc: newState.unrealizedPnLPerc,
                initialBalance: newState.initialBalance,
                currentBalance: newState.currentBalance,
                currentProfit: newState.currentProfit,
                currentProfitPerc: newState.currentProfitPerc,
                trades: newState.trades,
                exposureSide: newState.exposureSide,
                stateTimestamp: newState.stateTimestamp,
                openPositions: newState.openPositions,
              };

              that.instanceList.push(newInstance);
            }
          },
          error(err) {
            console.error('err', err);
          },
        });
    },
  },
};
</script>
