<!-- ============================================================ -->
<template>
    <footer class="footer">
        <div class="container">
            <div class="content has-text-centered">
                <div v-if="line<=3">
                    Made by <b-icon icon="alien" style="color:#d91b5d"></b-icon> eating <b-icon icon="food" style="color:#f79520"></b-icon> at <strong style="color:#4a277a">GGL</strong>.
                </div>
                <div v-else-if="line<=6">
                    Pick up the <b-icon icon="cash-100" style="color:green"></b-icon> and <b-icon icon="run"></b-icon>.
                </div>
                <div v-else-if="line<=9">
                    Made with
                        <b-icon icon="vuejs" style="color:#42b983"></b-icon>
                        <b-icon icon="graphql" style="color:#e10098"></b-icon>
                        <b-icon icon="nodejs" style="color:#43853d"></b-icon>
                    .
                </div>
                <div v-else>
                    CC was here
                </div>
                <img src="/static/logo.png" style="height: 40px; margin-top: 7px;">
            </div>
        </div>
    </footer>
</template>


<!-- ============================================================ -->
<script>
'use strict';

import _ from 'lodash';
//
require('../assets/app.scss');

export default {
    computed:
    {
        line() {
            return _.random(1,10);
        },
    },
}

</script>
