//===================================================================
'use strict';
//===================================================================
import Vue from 'vue';
import VueRouter from 'vue-router';

//===================================================================
Vue.use(VueRouter);

//===================================================================

import Dashboard from '../components/dashboard/Dashboard.vue';

import Performance from '../components/performance/Performance.vue';
import ExchangeIndex from '../components/exchanges/ExchangeIndex.vue';
import WalletIndex from '../components/wallets/WalletIndex.vue';
import TradeList from '../components/trades/TradeList.vue';
import ReportIndex from '../components/reports/ReportIndex.vue';
import GekkoBotPerformance from '../components/gekko/GekkoBotPerformance.vue';
import Instances from '../components/gekko/Instances.vue';
import InstanceGroupManagement from '../components/gekko/InstanceGroupManagement.vue';

import LogIn from '../components/LogIn.vue';

//===================================================================
//
//===================================================================
const routes = [
  { path: '/', name: 'index', redirect: { name: 'dashboard' } },
  //
  { path: '/dashboard', name: 'dashboard', component: Dashboard },
  //
  { path: '/performance', name: 'performance', component: Performance },
  //
  { path: '/exchanges', name: 'exchanges', component: ExchangeIndex },
  { path: '/exchange/:id', name: 'exchange', component: ExchangeIndex },
  //
  { path: '/wallets', name: 'wallets', component: WalletIndex },
  //
  { path: '/trades', name: 'trades', component: TradeList },
  //
  { path: '/reports', name: 'reports', component: ReportIndex },
  {
    path: '/gekko-bot-performance',
    name: 'gekkoBotPerformance',
    component: GekkoBotPerformance,
  },
  { path: '/gekko-instances', name: 'gekkoInstances', component: Instances },
  {
    path: '/gekko-instance-groups',
    name: 'gekkoInstanceGroups',
    component: InstanceGroupManagement,
  },
  //
  { path: '/login', name: 'login', component: LogIn },
];

//===================================================================
export default new VueRouter({ routes, mode: 'history' });
