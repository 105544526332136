<!-- ============================================================ -->
<template>
    <main>

        <h1 class="title">Reports</h1>

        <article class="message is-warning">
            <div class="message-body">
                Contact the administrator to get the files.
            </div>
        </article>

        <div style="position:relative">

            <b-loading :is-full-page="false" :active="isLoadingData"></b-loading>

            <div v-for="file in reportFiles" v-bind:key="file.id" style="margin-bottom:20px">

                <!-- a :href="apiUrl + '/static/reports/' + file.filename" -->
                <a :href="'/reports/' + file.filename" v-on:click.prevent="downloadFile(file.filename)">
                    <b-icon icon="file-excel" size="is-small"></b-icon>
                    <span>{{ file.name }} [{{ file.filename }}]</span>
                </a>
                <br>
                <b-tag type="is-primary">
                    <b-icon icon="calendar-range" size="is-small"></b-icon>
                    <span>{{file.month}}. {{file.year}}</span>
                </b-tag>
                <b-tag type="is-info">
                    <b-icon :icon="reportTypeIcons[file.type]" size="is-small"></b-icon>
                    <span>{{reportTypenames[file.type]}}</span>
                </b-tag>
                <br>
                {{file.createdAt | formatDateTimeFromTimestamp() }}

            </div>

        </div>

    </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';

import gql from 'graphql-tag';
//
import reportFileListQuery from '../../graphql/queries/reports/reportFileList.gql';

export default {
    name : 'reports',
    //
    data()
    {
        const data =
        {
            apiUrl: 'http://api.trading-reporting.net',
            //
            isLoadingData: true,
            //
            reportTypenames: {
                trade: 'Trade',
                margin_trade: 'Margin trade',
            },
            reportTypeIcons: {
                trade: 'scale-balance',
                margin_trade: 'margin',
            },
            //
            reportFiles: [],
        };
        //
        return data;
    },
    //
    apollo:
    {
        reportFiles() {
            return {
                query: gql`${reportFileListQuery}`,
                update: null,
                result(result)
                {
                    this.isLoadingData = false;
                },
            };
        },
    },
    //
    methods:
    {
        downloadFile(filename)
        {
            console.log('download!', this.apiUrl, filename);

            // TODO
            // 1) get file contents with axios
            const options = {
                method: 'GET',
                //headers: { 'Content-Type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "*" },
                url: `${this.apiUrl}/static/reports/${filename}`,
            }

            this.axios(options)
            .then(res =>
            {
                console.log('got file contents!');
                console.log(res);
            });

            // 2) send file contents as a download to user


        },
    },
    watch:
    {
        //
    },
    //
    created()
    {
        this.demandLogin();
    },
    mounted()
    {
        //
    }
};
</script>
