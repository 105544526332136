<!-- ============================================================ -->
<template>
  <main>
    <div>
      <b-field>
        <b-radio-button disabled type="is-light">
          <span>Load filter:</span>
        </b-radio-button>
        <b-radio-button
          :key="0"
          v-model="selectedFilter"
          :native-value="0"
          type="is-warning"
        >
          <span>No filter</span>
        </b-radio-button>
        <template v-for="filter in filters">
          <b-radio-button
            :key="filter.id"
            v-model="selectedFilter"
            :native-value="filter.id"
            type="is-primary"
          >
            <span>{{ filter.name }}</span>
          </b-radio-button>
        </template>
      </b-field>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Total value of coins</p>
          </header>
          <div class="card-content">
            <div class="content is-size-4 has-text-weight-bold">
              {{ totalCoinsValue | formatCurrency() }}
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingTotalCoinsValue"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Portfolio on day one of this Q</p>
          </header>
          <div class="card-content">
            <div class="content is-size-4 has-text-weight-bold">
              {{ startOfQuarterPortfolioValue | formatCurrency() }}
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingPortfolioValue"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">FIAT</p>
          </header>
          <div class="card-content">
            <div class="content is-size-4 has-text-weight-bold">
              {{ fiatValue | formatCurrency() }}
            </div>
          </div>
          <b-loading :is-full-page="false" :active.sync="isLoadingFiatValue" />
        </div>
      </div>
    </div>

    <div class="tile is-ancestor">
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Total value of index</p>
            <div>
              <b-icon
                v-if="dailyIndexChange < 0"
                icon="arrow-bottom-right-thick"
                type="is-danger"
                size="is-small"
              />
              <b-icon
                v-else-if="dailyIndexChange > 0"
                icon="arrow-top-right-thick"
                type="is-success"
                size="is-small"
              />
              <b-icon
                v-else
                icon="arrow-right-thick"
                type="is-info"
                size="is-small"
              />
              <span :class="styleChangeTag(dailyIndexChange)"
                >{{ dailyIndexChange | formatAmount(4) }} %</span
              >
            </div>
          </header>
          <div class="card-content">
            <div class="content is-size-4 has-text-weight-bold">
              {{ dailyIndexValue | formatCurrency() }}
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingDailyIndexValue"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Portfolio today</p>
            <div>
              <b-icon
                v-if="portfolioChange < 0"
                icon="arrow-bottom-right-thick"
                type="is-danger"
                size="is-small"
              />
              <b-icon
                v-else-if="portfolioChange > 0"
                icon="arrow-top-right-thick"
                type="is-success"
                size="is-small"
              />
              <b-icon
                v-else
                icon="arrow-right-thick"
                type="is-info"
                size="is-small"
              />
              <span :class="styleChangeTag(portfolioChange)"
                >{{ portfolioChange | formatAmount(4) }} %</span
              >
            </div>
          </header>
          <div class="card-content">
            <div class="content is-size-4 has-text-weight-bold">
              {{ portfolioValue | formatCurrency() }}
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingPortfolioValue"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Trades yesterday</p>
          </header>
          <div class="card-content">
            <div class="content is-size-4 has-text-weight-bold">
              {{ dailyTradeCount }} Trades with {{ dailyUniqueCoins.length }}
              <b-button
                type="is-info"
                @click="showDailyUniqueCoins = !showDailyUniqueCoins"
                >Coins</b-button
              >
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingDailyTradeCount"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <b-notification
          type="is-info"
          :active.sync="showDailyUniqueCoins"
          aria-close-label="Close"
        >
          <ul id="example-1">
            <li v-for="coin in dailyUniqueCoins">
              {{ coin }}
            </li>
          </ul>
        </b-notification>
      </div>
    </div>

    <div class="tile is-ancestor">
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Value of each coin in $</p>
          </header>
          <div class="card-content">
            <div class="content">
              <currency-chart
                :chart-data="currencyChartData"
                :options="currencyChartOptions"
              />
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingCurrencyChart"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Trades per exchange yesterday</p>
          </header>
          <div class="card-content">
            <div class="content">
              <currency-chart
                :chart-data="tradesPerExchangeData"
                :options="tradesPerExchangeOptions"
              />
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingTradesPerExchange"
          />
        </div>
      </div>
      <div class="tile is-parent is-3">
        <div class="tile is-child card">
          <header class="card-header">
            <p class="card-header-title">Trades last 30 days</p>
          </header>
          <div class="card-content">
            <div class="content">
              <index-chart
                :chart-data="monthlyTradeCountChartData"
                :options="monthlyTradeCountChartOptions"
              />
            </div>
          </div>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingMonthlyTradeCountChart"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';
import gql from 'graphql-tag';
import filterListQuery from '../../graphql/queries/performance/filterList.gql';
//
import dailyIndexMutation from '../../graphql/queries/dashboard/dailyIndex.gql';
import dailyPorfolioMutation from '../../graphql/queries/dashboard/dailyPortfolio.gql';
import totalCoinsMutation from '../../graphql/queries/dashboard/totalCoins.gql';
import fiatMutation from '../../graphql/queries/dashboard/fiat.gql';
import currencyChartDataMutation from '../../graphql/queries/dashboard/currencyChartData.gql';
import dailyTradeCountMutation from '../../graphql/queries/dashboard/dailyTradeCount.gql';
import monthlyTradeCountMutation from '../../graphql/queries/dashboard/monthlyTradeCount.gql';
import tradesByExchangeMutation from '../../graphql/queries/dashboard/tradesByExchange.gql';

import CurrencyChart from '../performance/CurrencyChart.js';
import IndexChart from '../performance/IndexChart.js';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';
import auth from '../../auth';

export default {
  name: 'Dashboard',
  components: {
    CurrencyChart,
    IndexChart,
  },
  //
  data() {
    return {
      isLoadingMonthlyTradeCountChart: false,
      isLoadingTradesPerExchange: false,
      isLoadingCurrencyChart: false,
      isLoadingDailyTradeCount: false,
      isLoadingPortfolioValue: false,
      isLoadingDailyIndexValue: false,
      isLoadingFiatValue: false,
      isLoadingTotalCoinsValue: false,
      //
      dailyIndexValue: 0,
      dailyIndexChange: 0,
      portfolioValue: 0,
      portfolioChange: 0,
      startOfQuarterPortfolioValue: 0,
      totalCoinsValue: 0,
      fiatValue: 0,
      dailyTradeCount: 0,
      dailyUniqueCoins: [],
      showDailyUniqueCoins: false,

      currencyChartData: null,
      currencyChartOptions: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) =>
              `${
                data.labels[tooltipItem.index]
              }: ${this.$options.filters.formatCurrency(
                data.datasets[0].data[tooltipItem.index]
              )}`,
          },
        },
      },

      tradesPerExchangeData: null,
      tradesPerExchangeOptions: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
        },
      },

      monthlyTradeCountChartData: null,
      monthlyTradeCountChartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      },

      filters: [],
      selectedFilter: 0,

      currentDate:
        process.env.NODE_ENV === 'development'
          ? '2020-04-02'
          : this.$moment().format('YYYY-MM-DD'),
    };
  },
  watch: {
    selectedFilter() {
      this.getDailyPorfolioValue();
      this.updateCurrencyChart();
      this.getTotalCoinsValue();
      this.getFiatValue();
      this.updateDailyTradeCount();
      this.updateMonthlyTradeCount();
      this.updateTradesByExchangeChart();
    },
  },
  //
  mounted() {
    if (auth.user.authenticated) {
      this.getTotalCoinsValue();
      this.getDailyIndexValue();
      this.getDailyPorfolioValue();
      this.updateCurrencyChart();
      this.getFiatValue();
      this.updateDailyTradeCount();
      this.updateMonthlyTradeCount();
      this.updateTradesByExchangeChart();
    }
  },

  //
  created() {
    this.demandLogin();
  },
  apollo: {
    filters() {
      //
      return {
        query: gql`
          ${filterListQuery}
        `,
        update: null,
      };
    },
  },
  methods: {
    styleChangeTag(value) {
      return {
        'tag is-danger': value < 0,
        'tag is-success': value > 0,
        'tag is-info': value == 0,
      };
    },

    getDailyIndexValue() {
      this.isLoadingDailyIndexValue = true;

      const variables = {
        input: {
          date: this.currentDate,
        },
      };

      this.$apollo
        .mutate({
          mutation: dailyIndexMutation,
          variables,
          update: (store, { data: { dailyIndexValue } }) => {
            this.dailyIndexValue = dailyIndexValue.value;
            this.dailyIndexChange = dailyIndexValue.percentChange;
            //
            this.isLoadingDailyIndexValue = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: getDailyIndexValue', error);
          this.isLoadingDailyIndexValue = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    getTotalCoinsValue() {
      this.isLoadingTotalCoinsValue = true;

      const variables = {
        input: {
          date: this.currentDate,
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: totalCoinsMutation,
          variables,
          update: (store, { data: { totalCoinsValue } }) => {
            this.totalCoinsValue = totalCoinsValue.value;
            //
            this.isLoadingTotalCoinsValue = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: getTotalCoinsValue', error);
          this.isLoadingTotalCoinsValue = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    getDailyPorfolioValue() {
      this.isLoadingPortfolioValue = true;

      const variables = {
        input: {
          date: this.currentDate,
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: dailyPorfolioMutation,
          variables,
          update: (store, { data: { dailyPortfolioValue } }) => {
            this.portfolioValue = dailyPortfolioValue.value;
            this.portfolioChange = dailyPortfolioValue.percentChange;
            this.startOfQuarterPortfolioValue =
              dailyPortfolioValue.valueOnStartOfQuarter;
            //
            this.isLoadingPortfolioValue = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: getDailyPorfolioValue', error);
          this.isLoadingPortfolioValue = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    getFiatValue() {
      this.isLoadingFiatValue = true;

      const variables = {
        input: {
          date: this.currentDate,
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: fiatMutation,
          variables,
          update: (store, { data: { fiatValue } }) => {
            this.fiatValue = fiatValue.value;
            //
            this.isLoadingFiatValue = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: getFiatValue', error);
          this.isLoadingFiatValue = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    updateCurrencyChart() {
      this.isLoadingCurrencyChart = true;

      const variables = {
        input: {
          date: this.currentDate,
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: currencyChartDataMutation,
          variables,
          update: (store, { data: { currencyChartData } }) => {
            const labels = currencyChartData.map((item) => item.name);
            const data = currencyChartData.map((item) =>
              item.usdvalue.toFixed(2)
            );

            this.currencyChartData = {
              labels,
              datasets: [
                {
                  backgroundColor: Tableau20,
                  data,
                },
              ],
            };
            //
            this.isLoadingCurrencyChart = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateCurrencyChart', error);
          this.isLoadingCurrencyChart = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    updateDailyTradeCount() {
      this.isLoadingDailyTradeCount = true;

      const variables = {
        input: {
          date: this.$moment(this.currentDate)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: dailyTradeCountMutation,
          variables,
          update: (store, { data: { dailyTradeCount } }) => {
            this.dailyTradeCount = dailyTradeCount.tradeCount;
            this.dailyUniqueCoins = dailyTradeCount.uniqueCoins;
            //
            this.isLoadingDailyTradeCount = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateDailyTradeCount', error);
          this.isLoadingDailyTradeCount = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    updateMonthlyTradeCount() {
      this.isLoadingMonthlyTradeCountChart = true;

      const variables = {
        input: {
          date: this.currentDate,
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: monthlyTradeCountMutation,
          variables,
          update: (store, { data: { monthlyTradeCount } }) => {
            const {
              monthlyTradeCountLabels,
              monthlyTradeCountData,
            } = monthlyTradeCount.reduce(
              (result, dailyTradeCount) => {
                result.monthlyTradeCountLabels.push(dailyTradeCount.date);
                result.monthlyTradeCountData.push(dailyTradeCount.tradeCount);

                return result;
              },
              { monthlyTradeCountLabels: [], monthlyTradeCountData: [] }
            );

            this.monthlyTradeCountChartData = {
              labels: monthlyTradeCountLabels,
              datasets: [
                {
                  label: 'Number of trades',
                  borderColor: '#fbb133',
                  fill: false,
                  data: monthlyTradeCountData,
                },
              ],
            };

            //
            this.isLoadingMonthlyTradeCountChart = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateMonthlyTradeCount', error);
          this.isLoadingMonthlyTradeCountChart = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    updateTradesByExchangeChart() {
      this.isLoadingTradesPerExchange = true;

      const variables = {
        input: {
          date: this.$moment(this.currentDate)
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
          filterName:
            this.filters && this.selectedFilter !== 0
              ? this.filters.find((filter) => filter.id === this.selectedFilter)
                  .name
              : undefined,
        },
      };

      this.$apollo
        .mutate({
          mutation: tradesByExchangeMutation,
          variables,
          update: (store, { data: { tradesByExchange } }) => {
            const labels = tradesByExchange.map((item) => item.exchangeName);
            const data = tradesByExchange.map((item) => item.tradeCount);

            this.tradesPerExchangeData = {
              labels,
              datasets: [
                {
                  backgroundColor: Tableau20,
                  data,
                },
              ],
            };
            //
            this.isLoadingTradesPerExchange = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateTradesByExchangeChart', error);
          this.isLoadingTradesPerExchange = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },
  },
};
</script>
