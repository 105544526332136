<!-- ============================================================ -->
<template>
  <section>
    <div v-for="group in gekkoGroupPerformance">
      <gekko-group-duration
        v-bind:started-at="group.startedAt"
        v-bind:last-update="group.lastUpdate"
        v-bind:group-id="group.groupId"
        ref="duration"
      />
      <div class="divider is-left">{{ group.groupName }}</div>
      <div
        :class="(group.running ? 'has-text-success is-size-5' : 'has-text-danger is-size-5')"
      >
        {{ group.running ? 'RUN' : 'STOP' }}
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-vertical is-6">
          <div class="tile">
            <div class="tile is-parent is-4">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title is-size-7">
                    <a :href="group.chartingUrl" target="_blank">
                      {{ group.groupName }}
                    </a>
                  </p>
                  <span class="tag is-info">{{ group.candleSize }}M</span>
                </header>
                <div class="card-content">
                  <div class="content is-size-5 has-text-weight-bold">
                    {{ group.baseSymbol }} {{ group.quoteSymbol }}
                  </div>
                  {{ group.strategyName }}
                </div>
              </div>
            </div>
            <div class="tile is-parent is-4">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title">Duration</p>
                </header>
                <div class="card-content">
                  <div
                    class="content is-size-5"
                    @mouseover="showHideDurationInfo($event, group.groupId)"
                    @mouseleave="showHideDurationInfo($event, group.groupId)"
                  >
                    {{
                      group.lastUpdate
                        | moment('diff', group.startedAt)
                        | duration('humanize')
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent is-4">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title">UN - PnL</p>
                </header>
                <div class="card-content">
                  <div class="content is-size-5">
                    {{ group.unrealizedPnLPerc | formatAmount(4) }}% ({{
                      group.unrealizedPnL | formatAmount(4)
                    }})
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent is-6">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title is-size-7">
                    PORTFOLIO IN BTC TODAY
                  </p>
                  <div>
                    <b-icon
                      v-if="group.portfolioBtcChange < 0"
                      icon="arrow-bottom-right-thick"
                      type="is-danger"
                      size="is-small"
                    />
                    <b-icon
                      v-else-if="group.portfolioBtcChange > 0"
                      icon="arrow-top-right-thick"
                      type="is-success"
                      size="is-small"
                    />
                    <b-icon
                      v-else
                      icon="arrow-right-thick"
                      type="is-info"
                      size="is-small"
                    />
                    <span class="tag is-success"
                      >{{ group.portfolioBtcChange | formatAmount(4) }} %</span
                    >
                  </div>
                </header>
                <div class="card-content">
                  <div class="content is-size-5 has-text-weight-bold">
                    {{ group.btcAmountOnEndDate | formatAmount(4) }} BTC
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent is-6">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title is-size-6">
                    PORTFOLIO IN $ TODAY
                  </p>
                  <div>
                    <b-icon
                      v-if="group.portfolioUsdChange < 0"
                      icon="arrow-bottom-right-thick"
                      type="is-danger"
                      size="is-small"
                    />
                    <b-icon
                      v-else-if="group.portfolioUsdChange > 0"
                      icon="arrow-top-right-thick"
                      type="is-success"
                      size="is-small"
                    />
                    <b-icon
                      v-else
                      icon="arrow-right-thick"
                      type="is-info"
                      size="is-small"
                    />
                    <span class="tag is-success"
                      >{{ group.portfolioUsdChange | formatAmount(4) }} %</span
                    >
                  </div>
                </header>
                <div class="card-content">
                  <div class="content">
                    <span class="is-size-5">
                      {{ group.portfolioUsdOnEndDate | formatCurrency() }}</span
                    >
                    <span class="is-size-10"
                      >({{ group.fiatOnEndDate | formatCurrency() }} in
                      FIAT)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-vertical is-4">
          <div class="tile">
            <div class="tile is-parent is-6">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title">PROFIT</p>
                </header>
                <div class="card-content">
                  <div class="content is-size-5">
                    {{ group.profit | formatCurrency() }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent is-6">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title">INVENTORY</p>
                </header>
                <div class="card-content">
                  <div class="content is-size-5 has-text-weight-bold">
                    {{ group.inventoryName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tile">
            <div class="tile is-parent is-6">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title">
                    CURR POS / ALL POS
                  </p>
                </header>
                <div class="card-content">
                  <div class="content is-size-5">
                    {{ group.currentOpenPositionCount }} /
                    {{ group.allOpenPositionCount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tile is-parent is-6">
              <div class="tile is-child card">
                <header class="card-header">
                  <p class="card-header-title">ACC EXPOSURE</p>
                </header>
                <div class="card-content">
                  <div class="content is-size-5">
                    {{ group.exposureSide }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-vertical is-3">
          <div class="tile is-parent is-11">
            <div class="tile is-child card">
              <header class="card-header">
                <p class="card-header-title">POSITIONS OPENED 30 DAYS</p>
              </header>
              <div class="card-content">
                <div class="content is-size-5">
                  <open-positions-graph v-bind:group-id="group.groupId" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoadingGekkoGroupPerformance"
    />
  </section>
</template>

<!-- ============================================================ -->
<script>
'use strict';
import GekkoGroupPerformanceMutation from '../../graphql/queries/gekko/gekkoGroupPerformance.gql';
import GekkoGroupPerformanceUpdateSubscription from '../../graphql/queries/gekko/gekkoGroupPerformanceUpdateSubscription.gql';

import OpenPositionsGraph from './OpenPositionsGraph.vue';
import GekkoGroupDuration from './GekkoGroupDuration.vue';

import auth from '../../auth';

export default {
  name: 'GekkoBotPerformance',
  components: {
    OpenPositionsGraph,
    GekkoGroupDuration,
  },
  data() {
    return {
      gekkoGroupPerformance: [],
      isLoadingGekkoGroupPerformance: false,

      showDurationInfo: {},

      currentDate:
        process.env.NODE_ENV === 'development'
          ? '2020-04-02'
          : this.$moment().format('YYYY-MM-DD'),
    };
  },

  //
  mounted() {
    if (auth.user.authenticated) {
      this.getGekkoGroupPerformanceData();
    }
  },

  //
  created() {
    this.demandLogin();
  },
  apollo: {},
  methods: {
    showHideDurationInfo(event, groupId) {
      const showPopup = (this.showDurationInfo[groupId] = !this
        .showDurationInfo[groupId]);

      this.$refs.duration.forEach((durr) => {
        if (durr.groupId == groupId) {
          durr.setShowPopup(showPopup);
        }
      });
    },
    getGekkoGroupPerformanceData() {
      this.isLoadingGekkoGroupPerformance = true;
      const variables = {
        input: {},
      };

      this.$apollo
        .mutate({
          mutation: GekkoGroupPerformanceMutation,
          variables,
          update: (store, { data: { gekkoGroupPerformance } }) => {
            gekkoGroupPerformance.forEach(
              (group) => (this.showDurationInfo[group.groupId] = false)
            );

            this.gekkoGroupPerformance = gekkoGroupPerformance;

            //
            this.isLoadingGekkoGroupPerformance = false;

            this.subscribeToPerformanceUpdate();
          },
        })
        .catch((error) => {
          console.error(
            'GekkoBotPerformance.vue: getGekkoGroupPerformanceData',
            error
          );
          this.isLoadingGekkoGroupPerformance = false;
          this.$buefy.toast.open({
            message: 'Error getting data',
            type: 'is-danger',
          });
        });
    },

    subscribeToPerformanceUpdate() {
      const that = this;
      this.$apollo
        .subscribe({
          query: GekkoGroupPerformanceUpdateSubscription,
          variables: {},
        })
        .subscribe({
          next(data) {
            const newPerformance = data.data.gekkoGroupPerformanceUpdated;
            const index = that.gekkoGroupPerformance.findIndex(
              (group) => group.groupId === newPerformance.groupId
            );

            if (index > -1) {
              that.gekkoGroupPerformance.splice(index, 1, newPerformance);
            } else {
              that.gekkoGroupPerformance.push(newPerformance);
            }
          },
          error(err) {
            console.error('err', err);
          },
        });
    },
  },
};
</script>
<style scoped>
.notification.is-info {
  position: absolute;
  z-index: 1;
}
</style>
