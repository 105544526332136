//===================================================================
'use strict';
//===================================================================
import Vue from 'vue';
import Buefy from 'buefy';
//
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import VueApollo from 'vue-apollo';
// import VeeValidate from 'vee-validate';
import VueMoment from 'vue-moment';
import moment from 'moment';
//import removeTrailingZeros from 'remove-trailing-zeros';
//
import Axios from 'axios';
import VueAxios from 'vue-axios';
//
import router from './router';
import auth from './auth';
//
require('./components/global');
//

//===================================================================
let apiUrl = 'https://api.trading-reporting.net';
let wsUrl = 'wss://api.trading-reporting.net';
if (process.env.NODE_ENV === 'development') {
  apiUrl = 'http://localhost:9865';
  wsUrl = 'ws://localhost:9865';
}

// apollo ---
const httpLink = new HttpLink({
  uri: `${apiUrl}/graphql`, // url for graphQL / apollo
});

const wsLink = new WebSocketLink({
  uri: `${wsUrl}/graphql`,
  options: {
    reconnect: true,
  },
});

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    auth.logout();
    if (location.pathname !== '/login') {
      router.push({
        path: '/login',
        query: {
          return: location.pathname + location.search,
        },
      });
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const accessToken = auth.getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  };
});

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const apolloClient = new ApolloClient({
  link: from([logoutLink, authLink, splitLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
// apollo ---

Vue.use(Buefy);
//Vue.use( VeeValidate );
Vue.use(VueMoment);
//
Vue.use(VueAxios, Axios);
Vue.axios.defaults.baseURL = `${apiUrl}/v1`; // url to the auth / user api (non-graphQL / apollo)
Vue.axios.defaults.headers.common = auth.getAuthHeader();

import App from './components/App.vue';
//
auth.checkAuth();
//
Vue.mixin({
  methods: {
    demandLogin: () => {
      if (!auth.user.authenticated) router.push('/login');
    },
  },
});

//

Vue.filter('formatDateTimeFromTimestamp', (value) => {
  const date = new Date(parseInt(value));
  return moment(date).format('DD.MM.YYYY HH:mm:ss');
});
Vue.filter('formatAmount', (input, decimals = 8) => {
  return new Intl.NumberFormat('sl-SI', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(input);
});
Vue.filter('formatCurrency', (input, decimals = 2) => {
  return new Intl.NumberFormat('sl-SI', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  }).format(input);
});

//===================================================================
//
//===================================================================
export default new Vue({
  el: '#app',
  apolloProvider,
  render: (h) => h(App),
  //
  router,
});
