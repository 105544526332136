<!-- ============================================================ -->
<template>
  <div class="columns">
    <div class="column is-one-fifth">
      <b-sidebar position="static" type="is-light" open>
        <b-menu>
          <b-menu-list label="Gekko groups">
            <b-menu-item
              v-for="instanceGroup in instanceGroupList"
              :key="instanceGroup.id"
              :label="instanceGroup.name"
              @click="setCurrentId(instanceGroup.id)"
            ></b-menu-item>
            <b-loading
              :is-full-page="false"
              :active.sync="isLoadingInstanceGroupList"
            />
          </b-menu-list>
          <b-menu-list label="Actions">
            <b-menu-item
              icon="new-box"
              label="New group"
              @click="clearForm"
              active
            />
          </b-menu-list>
        </b-menu>
      </b-sidebar>
    </div>
    <div class="column is-one-third">
      <div class="card">
        <div class="card-content">
          <p v-if="!currentId" class="title">
            Add new group
          </p>
          <p v-if="!!currentId" class="title">
            Edit group
          </p>
          <b-field label-position="on-border" label="Name">
            <b-input v-model="currentGroup.name"></b-input>
          </b-field>
          <b-field label-position="on-border" label="Candle size (in minutes)">
            <b-input
              placeholder="Number"
              type="number"
              min="1"
              v-model="currentGroup.candleSize"
            ></b-input>
          </b-field>
          <b-field label-position="on-border" label="Charting url base">
            <b-input v-model="currentGroup.chartingUrlBase"></b-input>
          </b-field>
          <b-field label-position="on-border" label="Inventory">
            <b-autocomplete
              v-model="selectedInventoryName"
              field="name"
              :data="inventorySearchResult"
              placeholder="e.g. Kraken-GGL"
              icon="magnify"
              keep-first
              :loading="inventorySearching"
              @typing="searchInventories"
              @select="(option) => (selectedInventory = option)"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field label-position="on-border" label="Exchange">
            <b-autocomplete
              v-model="selectedExchangeName"
              field="name"
              :data="exchangeSearchResult"
              placeholder="e.g. Kraken"
              icon="magnify"
              keep-first
              :loading="exchangeSearching"
              @typing="searchExchanges"
              @select="setExchange"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field label-position="on-border" label="Margin product">
            <b-autocomplete
              v-model="selectedMarginProductPair"
              field="pair"
              :data="marginProductSearchResult"
              placeholder="e.g. BTC/USD"
              icon="magnify"
              keep-first
              :loading="marginProductSearching"
              @typing="searchMarginProducts"
              @select="(option) => (selectedMarginProduct = option)"
              :disabled="(!selectedExchange || (selectedBaseProduct || selectedQuoteProduct))"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field label-position="on-border" label="Base Product">
            <b-autocomplete
              v-model="selectedBaseProductSymbol"
              field="symbol"
              :data="baseProductSearchResult"
              placeholder="e.g. BTC"
              icon="magnify"
              keep-first
              :loading="baseProductSearching"
              @typing="searchBaseProducts"
              @select="(option) => (selectedBaseProduct = option)"
              :disabled="(!selectedExchange || selectedMarginProduct)"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field label-position="on-border" label="Quote Product">
            <b-autocomplete
              v-model="selectedQuoteProductSymbol"
              field="symbol"
              :data="quoteProductSearchResult"
              placeholder="e.g. USD"
              icon="magnify"
              keep-first
              :loading="quoteProductSearching"
              @typing="searchQuoteProducts"
              @select="(option) => (selectedQuoteProduct = option)"
              :disabled="(!selectedExchange || selectedMarginProduct)"
            >
              <template slot="empty">No results found</template>
            </b-autocomplete>
          </b-field>
          <b-field label-position="on-border">
            <p class="control">
              <button
                v-if="!currentId"
                class="button is-primary"
                @click="createInstanceGroup"
              >
                Save
              </button>
              <button
                v-if="!!currentId"
                class="button is-primary"
                @click="updateInstanceGroup"
              >
                Update
              </button>
              <button
                v-if="!!currentId"
                class="button is-danger"
                @click="deleteInstanceGroup"
              >
                Delete
              </button>
            </p>
          </b-field>
          <b-loading
            :is-full-page="false"
            :active.sync="isLoadingInstanceGroupDetail"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- ============================================================ -->
<script>
'use strict';
import gql from 'graphql-tag';
import instanceGroupListQuery from '../../graphql/queries/gekko/instanceGroupList.gql';
import instanceGroupDetailQuery from '../../graphql/queries/gekko/instanceGroupDetail.gql';
import createInstanceGroupQuery from '../../graphql/queries/gekko/createInstanceGroup.gql';
import updateInstanceGroupQuery from '../../graphql/queries/gekko/updateInstanceGroup.gql';
import deleteInstanceGroupQuery from '../../graphql/queries/gekko/deleteInstanceGroup.gql';
import marginProductSearchMutation from '../../graphql/queries/inventory/marginProductSearch.gql';
import simpleProductSearchMutation from '../../graphql/queries/inventory/simpleProductSearch.gql';
import exchangeSearchByNameMutation from '../../graphql/queries/inventory/exchangeSearchByName.gql';
import inventorySearchByNameMutation from '../../graphql/queries/inventory/inventorySearchByName.gql';
//

export default {
  name: 'GekkoInstanceGroupManagement',
  //
  data() {
    return {
      currentId: null,
      currentGroup: { name: '', candleSize: '', chartingUrlBase: '' },
      instanceGroupDetail: {},

      exchangeSearchResult: [],
      exchangeSearching: false,
      selectedExchange: null,
      selectedExchangeName: '',

      inventorySearchResult: [],
      inventorySearching: false,
      selectedInventory: null,
      selectedInventoryName: '',

      marginProductSearchResult: [],
      marginProductSearching: false,
      selectedMarginProduct: null,
      selectedMarginProductPair: '',

      baseProductSearchResult: [],
      baseProductSearching: false,
      selectedBaseProduct: null,
      selectedBaseProductSymbol: '',

      quoteProductSearchResult: [],
      quoteProductSearching: false,
      selectedQuoteProduct: null,
      selectedQuoteProductSymbol: '',

      isLoadingInstanceGroupList: true,
      isLoadingInstanceGroupDetail: false,
    };
  },

  //
  mounted() {},

  //
  created() {
    this.demandLogin();
  },
  apollo: {
    instanceGroupList() {
      //
      return {
        query: gql`
          ${instanceGroupListQuery}
        `,
        update(result) {
          this.isLoadingInstanceGroupList = false;
          return result.gekkoInstanceGroups;
        },
      };
    },

    instanceGroupDetail() {
      return {
        query: gql`
          ${instanceGroupDetailQuery}
        `,
        manual: true,
        result({ data, loading }) {
          if (!loading) {
            const group = data.gekkoInstanceGroup;
            this.currentGroup.name = group.name;
            this.currentGroup.candleSize = group.candleSize;
            this.currentGroup.chartingUrlBase = group.chartingUrlBase;

            if (group.inventory) {
              this.selectedInventory = {
                id: group.inventoryId,
                name: group.inventory.name,
              };
              this.selectedInventoryName = group.inventory.name;
            } else {
              this.selectedInventory = null;
              this.selectedInventoryName = '';
            }

            this.selectedExchange = {
              id: group.exchangeId,
              name: group.exchange.name,
            };
            this.selectedExchangeName = group.exchange.name;

            this.selectedMarginProduct = null;
            this.selectedMarginProductPair = '';
            if (group.marginProduct) {
              this.selectedMarginProduct = {
                productId: group.marginProductId,
                pair: `${group.marginProduct.marginProducts[0].baseSymbol.code}/${group.marginProduct.marginProducts[0].quoteSymbol.code}`,
              };
              this.selectedMarginProductPair = this.selectedMarginProduct.pair;
            }

            this.selectedBaseProduct = null;
            this.selectedBaseProductSymbol = '';
            if (group.baseProduct) {
              this.selectedBaseProduct = {
                productId: group.baseProductId,
                symbol: group.baseProduct.simpleProducts[0].symbol.code,
              };
              this.selectedBaseProductSymbol = this.selectedBaseProduct.symbol;
            }

            this.selectedQuoteProduct = null;
            this.selectedQuoteProductSymbol = '';
            if (group.quoteProduct) {
              this.selectedQuoteProduct = {
                productId: group.quoteProductId,
                symbol: group.quoteProduct.simpleProducts[0].symbol.code,
              };
              this.selectedQuoteProductSymbol = this.selectedQuoteProduct.symbol;
            }

            this.isLoadingInstanceGroupDetail = false;
          }
        },
        variables() {
          return {
            gekkoInstanceGroupId: this.currentId,
          };
        },
        skip() {
          return !this.currentId;
        },
      };
    },
  },
  methods: {
    clearForm() {
      this.currentId = null;
      this.currentGroup = { name: '', candleSize: '', chartingUrlBase: '' };
      this.selectedExchange = null;
      this.selectedExchangeName = '';
      this.selectedInventory = null;
      this.selectedInventoryName = '';
      this.selectedMarginProduct = null;
      this.selectedMarginProductPair = '';
      this.selectedBaseProduct = null;
      this.selectedBaseProductSymbol = '';
      this.selectedQuoteProduct = null;
      this.selectedQuoteProductSymbol = '';
    },

    setCurrentId(id) {
      this.currentId = id;
    },

    setExchange(exchange) {
      this.selectedExchange = exchange;
      this.selectedMarginProduct = null;
      this.selectedMarginProductPair = '';
      this.selectedBaseProduct = null;
      this.selectedBaseProductSymbol = '';
      this.selectedQuoteProduct = null;
      this.selectedQuoteProductSymbol = '';
    },

    searchExchanges(searchTerm) {
      this.exchangeSearching = true;
      this.exchangeSearchResult = [];
      const variables = {
        input: {
          searchTerm,
          limit: 10,
        },
      };

      this.$apollo
        .mutate({
          mutation: exchangeSearchByNameMutation,
          variables,
          update: (store, { data: { exchangeSearchByName } }) => {
            this.exchangeSearchResult = exchangeSearchByName;
            //
            this.exchangeSearching = false;
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: searchExchanges',
            error
          );
          this.exchangeSearching = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    searchInventories(searchTerm) {
      this.inventorySearching = true;
      this.inventorySearchResult = [];
      const variables = {
        input: {
          searchTerm,
          limit: 10,
        },
      };

      this.$apollo
        .mutate({
          mutation: inventorySearchByNameMutation,
          variables,
          update: (store, { data: { inventorySearchByName } }) => {
            this.inventorySearchResult = inventorySearchByName;
            //
            this.inventorySearching = false;
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: searchInventories',
            error
          );
          this.inventorySearching = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    searchMarginProducts(searchTerm) {
      this.marginProductSearching = true;
      this.marginProductSearchResult = [];
      const variables = {
        input: {
          searchTerm,
          exchangeId: this.selectedExchange.id,
          limit: 10,
        },
      };

      this.$apollo
        .mutate({
          mutation: marginProductSearchMutation,
          variables,
          update: (store, { data: { marginProductSearch } }) => {
            this.marginProductSearchResult = marginProductSearch;
            //
            this.marginProductSearching = false;
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: searchMarginProducts',
            error
          );
          this.marginProductSearching = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    searchBaseProducts(searchTerm) {
      this.baseProductSearching = true;
      this.baseProductSearchResult = [];
      const variables = {
        input: {
          searchTerm,
          exchangeId: this.selectedExchange.id,
          limit: 10,
        },
      };

      this.$apollo
        .mutate({
          mutation: simpleProductSearchMutation,
          variables,
          update: (store, { data: { simpleProductSearch } }) => {
            this.baseProductSearchResult = simpleProductSearch;
            //
            this.baseProductSearching = false;
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: searchBaseProducts',
            error
          );
          this.baseProductSearching = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    searchQuoteProducts(searchTerm) {
      this.quoteProductSearching = true;
      this.quoteProductSearchResult = [];
      const variables = {
        input: {
          searchTerm,
          exchangeId: this.selectedExchange.id,
          limit: 10,
        },
      };

      this.$apollo
        .mutate({
          mutation: simpleProductSearchMutation,
          variables,
          update: (store, { data: { simpleProductSearch } }) => {
            this.quoteProductSearchResult = simpleProductSearch;
            //
            this.quoteProductSearching = false;
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: searchBaseProducts',
            error
          );
          this.quoteProductSearching = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },

    createInstanceGroup() {
      this.isLoadingInstanceGroupDetail = true;
      const variables = {
        input: {
          marginProductId:
            this.selectedMarginProduct && this.selectedMarginProduct.productId,
          baseProductId:
            this.selectedBaseProduct && this.selectedBaseProduct.productId,
          quoteProductId:
            this.selectedQuoteProduct && this.selectedQuoteProduct.productId,

          inventoryId: this.selectedInventory && this.selectedInventory.id,
          exchangeId: this.selectedExchange && this.selectedExchange.id,

          name: this.currentGroup.name,
          candleSize: parseInt(this.currentGroup.candleSize),
          chartingUrlBase: this.currentGroup.chartingUrlBase,
        },
      };

      this.$apollo
        .mutate({
          mutation: createInstanceGroupQuery,
          variables,
          update: () => {
            this.$apollo.queries.instanceGroupList.refetch();

            this.$buefy.toast.open({
              message: 'Grup created',
              type: 'is-success',
            });

            this.isLoadingInstanceGroupDetail = false;
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: createInstanceGroup',
            error
          );
          this.quoteProductSearching = false;
          this.isLoadingInstanceGroupDetail = false;
          this.$buefy.toast.open({
            message: 'Error creating instance group',
            type: 'is-danger',
          });
        });
    },

    updateInstanceGroup() {
      this.isLoadingInstanceGroupDetail = true;
      const variables = {
        input: {
          id: this.currentId,
          marginProductId:
            this.selectedMarginProduct && this.selectedMarginProduct.productId,
          baseProductId:
            this.selectedBaseProduct && this.selectedBaseProduct.productId,
          quoteProductId:
            this.selectedQuoteProduct && this.selectedQuoteProduct.productId,
          inventoryId: this.selectedInventory && this.selectedInventory.id,
          exchangeId: this.selectedExchange && this.selectedExchange.id,
          name: this.currentGroup.name,
          candleSize: parseInt(this.currentGroup.candleSize),
          chartingUrlBase: this.currentGroup.chartingUrlBase,
        },
      };
      console.log(variables);
      this.$apollo
        .mutate({
          mutation: updateInstanceGroupQuery,
          variables,
          update: () => {
            this.$apollo.queries.instanceGroupList.refetch();
            this.$apollo.queries.instanceGroupDetail.refetch();

            this.isLoadingInstanceGroupDetail = false;
            this.$buefy.toast.open({
              message: 'Grup updated',
              type: 'is-success',
            });
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: updateInstanceGroup',
            error
          );
          this.quoteProductSearching = false;
          this.isLoadingInstanceGroupDetail = false;
          this.$buefy.toast.open({
            message: 'Error updating instance group',
            type: 'is-danger',
          });
        });
    },

    deleteInstanceGroup() {
      this.isLoadingInstanceGroupDetail = true;
      const variables = {
        input: {
          id: this.currentId,
        },
      };

      this.$apollo
        .mutate({
          mutation: deleteInstanceGroupQuery,
          variables,
          update: (store, { data: { deleteGekkoInstanceGroup } }) => {
            if (deleteGekkoInstanceGroup) {
              this.$apollo.queries.instanceGroupList.refetch();
              this.clearForm();

              this.isLoadingInstanceGroupDetail = false;
              this.$buefy.toast.open({
                message: 'Grup deleted',
                type: 'is-success',
              });
            } else {
              this.isLoadingInstanceGroupDetail = false;
              this.$buefy.toast.open({
                message: 'There was a problem. Group not deleted.',
                type: 'is-danger',
              });
            }
          },
        })
        .catch((error) => {
          console.error(
            'GekkoInstanceGroupManagement.vue: deleteInstanceGroup',
            error
          );
          this.quoteProductSearching = false;
          this.isLoadingInstanceGroupDetail = false;
          this.$buefy.toast.open({
            message: 'Error deleting instance group',
            type: 'is-danger',
          });
        });
    },
  },
};
</script>
