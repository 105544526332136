<!-- ============================================================ -->

<template>
  <div class="container" style="margin-bottom: 20px;">
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'dashboard' }">
          <img src="/static/logo.png" style="height: 40px; margin-top: 7px;" />
        </b-navbar-item>
      </template>
      <template slot="start" v-if="user.authenticated">
        <b-navbar-item
          tag="router-link"
          class="is-ggl_violetdark"
          :to="{ name: 'performance' }"
        >
          <b-icon icon="chart-line" size="is-small"></b-icon>&nbsp;
          <span>Performance</span>
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          class="is-ggl_violetdark"
          :to="{ name: 'exchanges' }"
        >
          <b-icon icon="scale-balance" size="is-small"></b-icon>&nbsp;
          <span>Exchanges</span>
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          class="is-ggl_pinkdark"
          :to="{ name: 'trades' }"
        >
          <b-icon icon="shuffle" size="is-small"></b-icon>&nbsp;
          <span>Trades</span>
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          class="is-ggl_orangedark"
          :to="{ name: 'reports' }"
        >
          <b-icon icon="table" size="is-small"></b-icon>&nbsp;
          <span>Reports</span>
        </b-navbar-item>
        <b-navbar-dropdown label="gekko" class="is-ggl_violetlight" hoverable>
          <b-navbar-item
            tag="router-link"
            class="is-ggl_violetlight"
            :to="{ name: 'gekkoBotPerformance' }"
          >
            <b-icon icon="chart-line" size="is-small"></b-icon>&nbsp;
            <span>Performance</span>
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            class="is-ggl_violetlight"
            :to="{ name: 'gekkoInstanceGroups' }"
          >
            <b-icon icon="cog" size="is-small"></b-icon>&nbsp;
            <span>Manage instance groups</span>
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            class="is-ggl_violetlight"
            :to="{ name: 'gekkoInstances' }"
          >
            <b-icon icon="robot" size="is-small"></b-icon>&nbsp;
            <span>Gekko instances</span>
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>

      <template slot="end">
        <b-navbar-item tag="div" v-if="user.authenticated">
          <b-icon icon="account-box" type="is-info"></b-icon>
          <span>{{ user.username }}</span>
        </b-navbar-item>
        <b-navbar-item tag="div" v-if="user.authenticated" @click="logout()">
          <b-icon icon="logout" type="is-info"></b-icon>
          <span>Logout</span>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<!-- ============================================================ -->
<style lang="scss">
//#topbar { }
//#toplogo { max-height: none; height: 40px;}
</style>

<!-- ============================================================ -->
<script>
'use strict';

import auth from '../auth';
//
//require('../assets/app.scss');

export default {
  data() {
    const data = {
      user: auth.user,
    };
    //
    return data;
  },
  methods: {
    logout() {
      auth.logout();
      this.$router.push('/login');
    },
  },
};
</script>
