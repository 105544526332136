//===================================================================
'use strict';
//===================================================================
import Vue from 'vue';

//===================================================================
import PageTitle from './PageTitle.vue';

//===================================================================
//
//===================================================================
const components =
{
    PageTitle,
};

//===================================================================
Object.keys( components ).forEach( (cname) => Vue.component( cname, components[cname] ) );
