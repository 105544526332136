<!-- ============================================================ -->
<template>
  <main>
    <h1 class="title is-4">Trades</h1>

    <div class="columns">
      <div class="column is-one-quarter">
        <b-field label="From">
          <b-datepicker
            v-model="compareDateOne"
            :min-date="compareDateMin"
            :max-date="compareDateMax"
            :first-day-of-week="1"
            placeholder="First date"
            icon="calendar"
          >
            <button
              class="button"
              @click="compareDateOne = new Date($moment().subtract(1, 'month'))"
            >
              <b-icon icon="calendar-month"></b-icon>
              <span>1 month ago</span>
            </button>

            <button
              class="button"
              @click="compareDateOne = new Date($moment().subtract(1, 'week'))"
            >
              <b-icon icon="calendar-week"></b-icon>
              <span>1 week ago</span>
            </button>
            <button
              class="button"
              @click="compareDateOne = new Date($moment().subtract(1, 'day'))"
            >
              <b-icon icon="calendar-outline"></b-icon>
              <span>Yesterday</span>
            </button>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-one-quarter">
        <b-field label="To">
          <b-datepicker
            v-model="compareDateTwo"
            :min-date="compareDateMin"
            :max-date="compareDateMax"
            :first-day-of-week="1"
            placeholder="Second date"
            icon="calendar-today"
          >
            <button
              class="button"
              @click="compareDateTwo = new Date($moment().subtract(1, 'day'))"
            >
              <b-icon icon="calendar-outline"></b-icon>
              <span>Yesterday</span>
            </button>

            <button
              class="button is-primary is-outlined"
              @click="compareDateTwo = new Date()"
            >
              <b-icon icon="calendar-today"></b-icon>
              <span>Today</span>
            </button>
          </b-datepicker>
        </b-field>
      </div>
      <div class="column is-half"></div>
    </div>

    <b-field>
      <b-radio-button disabled type="is-light">
        <span>Load filter:</span>
      </b-radio-button>
      <b-radio-button
        v-bind:key="0"
        v-model="selectedFilter"
        v-bind:native-value="0"
        type="is-warning"
      >
        <span>No filter</span>
      </b-radio-button>
      <template v-for="filter in filters">
        <b-radio-button
          v-bind:key="filter.id"
          v-model="selectedFilter"
          v-bind:native-value="filter.id"
          type="is-primary"
        >
          <span>{{ filter.name }}</span>
        </b-radio-button>
      </template>
    </b-field>

    <b-field label="Exchanges">
      <b-field>
        <template v-for="exchange in exchanges">
          <b-checkbox-button
            v-bind:key="exchange.id"
            v-model="selectedExchanges"
            v-bind:native-value="exchange.id"
            type="is-info"
          >
            <b-icon v-bind:icon="exchange.icon" size="is-small"></b-icon>
            <span>{{ exchange.displayName }}</span>
          </b-checkbox-button>
        </template>
      </b-field>
    </b-field>

    <div class="field">
      <b-button @click="selectAllExchanges">
        Select all
      </b-button>
      <b-button @click="unselectAllExchanges">
        Unselect all
      </b-button>
    </div>

    <b-field label="Inventories">
      <b-taginput
        v-model="selectedInventories"
        field="inventory.name"
        autocomplete
        :allow-new="false"
      >
      </b-taginput>
    </b-field>

    <div class="field">
      <b-button @click="showTrades" type="is-info" outlined
        >Show trades</b-button
      >
    </div>

    <b-table :data="trades" :paginated="true" :perPage="50">
      <template slot-scope="props">
        <b-table-column field="timestamp" label="Date &amp; time">
          {{ props.row.timestamp | formatDateTimeFromTimestamp() }}
        </b-table-column>
        <b-table-column field="action" label="Action">
          {{ props.row.type }}
        </b-table-column>
        <b-table-column field="currency" label="Currency pair">
          {{ props.row.baseProduct.name }} - {{ props.row.quoteProduct.name }}
        </b-table-column>
        <b-table-column field="amount" label="Amount">
          {{ props.row.quantity | formatAmount(4) }}
        </b-table-column>
        <b-table-column field="value" label="Cost">
          {{ props.row.cost | formatAmount(4) }}
        </b-table-column>
        <b-table-column field="value_usd" label="Cost (USD)">
          <template v-if="props.row.costUsd">
            {{ props.row.costUsd | formatCurrency() }}
          </template>
          <template v-else>
            <b-icon icon="autorenew" custom-class="mdi-spin"> </b-icon>
          </template>
        </b-table-column>
        <b-table-column field="price_usd" label="Price (USD)">
          <template v-if="props.row.priceUsd">
            {{ props.row.priceUsd | formatCurrency(4) }}
          </template>
          <template v-else>
            <b-icon icon="autorenew" custom-class="mdi-spin"> </b-icon>
          </template>
        </b-table-column>
        <b-table-column field="productCategory" label="Type">
          {{ props.row.productCategory.name }}
        </b-table-column>
        <b-table-column field="inventory" label="Inventory">
          {{ props.row.inventory.name }}
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered" v-if="gotTrades">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
            </p>
            <p>No trades to show.</p>
          </div>
          <div class="content has-text-grey has-text-centered" v-else>
            <p>
              <b-icon icon="shuffle" size="is-large"> </b-icon>
            </p>
            <p>Select your settings and click 'Show trades'.</p>
          </div>
        </section>
      </template>
    </b-table>

    <b-loading :active="isLoadingData"></b-loading>
  </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';

import gql from 'graphql-tag';
//
import exchangeUtils from '../../utilities/exchanges';
//
import tradeListQuery from '../../graphql/queries/trades/tradeList.gql';
import exchangeInventoryListQuery from '../../graphql/queries/trades/exchangeInventoryList.gql';
import filterListQuery from '../../graphql/queries/performance/filterListWithValues.gql';
import usdPricesMutation from '../../graphql/queries/trades/usdPrices.gql';
import exchanges from '../../utilities/exchanges';

export default {
  name: 'trades',
  //
  data() {
    return {
      isLoadingData: true,
      //
      compareDateOne: new Date(this.$moment().subtract(7, 'day')),
      compareDateTwo: new Date(),
      compareDateMin: new Date('2018-01-01'),
      compareDateMax: new Date(),
      //
      trades: [],
      usdPrices: {},
      gotTrades: false,
      //
      exchanges: [],
      selectedExchanges: [],
      //
      exchangeInventories: [],
      selectedInventories: [],
      //
      filters: [],
      selectedFilter: 0,
    };
  },
  //
  apollo: {
    filters() {
      return {
        query: gql`
          ${filterListQuery}
        `,
      };
    },
    //
    exchangeInventories() {
      return {
        query: gql`
          ${exchangeInventoryListQuery}
        `,
        update: null,
        result(result) {
          this.exchanges = exchangeUtils.prepareForTabs(result.data.exchanges);
          this.selectAllExchanges();
          //
          this.updateSelectedInventories();

          this.isLoadingData = false;
        },
      };
    },
  },
  //
  methods: {
    selectAllExchanges() {
      this.selectedInventories = [];
      this.exchanges.forEach((exchange) =>
        this.selectedExchanges.push(exchange.id)
      );
      this.selectedExchanges = [...new Set(this.selectedExchanges)];
    },
    unselectAllExchanges() {
      this.selectedExchanges = [];
    },
    //
    showTrades() {
      const inventoryIds = this.selectedInventories.map(
        (inventory) => inventory.id
      );

      const from = new Date(this.$moment(this.compareDateOne).startOf('day'));
      const to = new Date(this.$moment(this.compareDateTwo).endOf('day'));
      //
      this.isLoadingData = true;

      this.$apollo
        .query({
          query: tradeListQuery,
          variables: { inventoryIds, from, to },
        })
        .then((result) => {
          this.trades = result.data.trades;
          this.gotTrades = true;
          //
          const requiredPriceConversions = [
            ...new Set(
              this.trades.map((trade) => {
                const quoteSplitCode = trade.quoteProduct.code.split('_');
                const quoteCode = quoteSplitCode[1]
                  ? quoteSplitCode[1]
                  : quoteSplitCode[0];
                if (quoteCode === 'USD') return;
                //
                const baseSplitCode = trade.baseProduct.code.split('_');
                const baseCode = baseSplitCode[1]
                  ? baseSplitCode[1]
                  : baseSplitCode[0];
                const date = this.$moment(
                  new Date(parseInt(trade.timestamp))
                ).format('YYYY-MM-DD');
                return `${quoteCode}_${date}`;
              })
            ),
          ].filter((c) => c);

          this.trades = this.trades.map((trade) => {
            const quoteCurrencyCode = trade.quoteProduct.code.split('_')[1];
            if (quoteCurrencyCode === 'USD') {
              trade.priceUsd = trade.price;
              trade.costUsd = trade.cost;
            }
            return trade;
          });

          if (requiredPriceConversions.length)
            return this.getUSDPrices(requiredPriceConversions);
          return Promise.resolve();
        })
        .then((res) => {
          this.trades = this.trades.map((trade) => {
            const baseCurrencyCode = trade.baseProduct.code.split('_')[1];
            const quoteCurrencyCode = trade.quoteProduct.code.split('_')[1];
            if (quoteCurrencyCode === 'USD') {
              trade.priceUsd = trade.price;
              trade.costUsd = trade.cost;
            } else {
              const date = this.$moment(parseInt(trade.timestamp)).format(
                'YYYY-MM-DD'
              );
              trade.priceUsd =
                this.usdPrices[`${quoteCurrencyCode}_${date}`] * trade.price;
              trade.costUsd =
                this.usdPrices[`${quoteCurrencyCode}_${date}`] * trade.cost;
            }

            return trade;
          });

          this.isLoadingData = false;
        });
    },
    //
    getUSDPrices(requiredPriceConversions) {
      const input = requiredPriceConversions.map((pair) => {
        const splitPair = pair.split('_');
        return {
          currency: splitPair[0],
          date: splitPair[1],
        };
      });

      const variables = {
        input,
      };

      return this.$apollo
        .mutate({
          mutation: usdPricesMutation,
          variables,
          update: (store, { data: { usdPrices } }) => {
            usdPrices.forEach((price) => {
              this.usdPrices[`${price.currency}_${price.date}`] =
                price.usdPrice;
            });
          },
        })
        .catch((error) => {
          //console.log(error);
          this.isLoadingIndex = false;
          this.$buefy.toast.open({
            message: 'Error getting USD prices',
            type: 'is-danger',
          });
        });
    },
    //
    updateSelectedInventories() {
      const preselectedExchanges = [
        ...new Set(
          this.selectedInventories.map((inventory) => inventory.exchange.id)
        ),
      ];

      const preselectedInventories = JSON.parse(
        JSON.stringify(this.selectedInventories)
      ).filter((inventory) =>
        this.selectedExchanges.includes(inventory.exchange.id)
      );

      this.selectedInventories = preselectedInventories
        .concat(
          JSON.parse(JSON.stringify(this.exchangeInventories)).filter(
            (inventory) =>
              this.selectedExchanges.includes(inventory.exchange.id) &&
              !preselectedExchanges.includes(inventory.exchange.id)
          )
        )
        .sort((a, b) => {
          if (a.inventory.name > b.inventory.name) return 1;
          if (a.inventory.name < b.inventory.name) return -1;
          return 0;
        });
    },
  },
  watch: {
    selectedExchanges() {
      this.updateSelectedInventories();
    },
    selectedFilter() {
      if (this.selectedFilter === 0) {
        this.selectAllExchanges();
        return;
      }

      const filter = this.filters.find(
        (filter) => filter.id === this.selectedFilter
      );

      //if (filter.values.exchanges.length>0) {
      //    // TODO
      //}

      if (filter.values.inventories.length > 0) {
        this.selectedInventories = this.exchangeInventories.filter((entry) =>
          filter.values.inventories.includes(entry.inventory.name)
        );
        this.selectedExchanges = [
          ...new Set(
            this.selectedInventories.map((inventory) => inventory.exchange.id)
          ),
        ];
      } else {
        this.selectAllExchanges();
      }
    },
  },
  //
  created() {
    this.demandLogin();
  },
};
</script>
