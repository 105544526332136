<template>
  <div>
    <index-chart
      :chart-data="openPositionsChartData"
      :options="openPositionsChartOptions"
    />
    <b-loading :is-full-page="false" :active.sync="isLoadingGraph" />
  </div>
</template>

<script>
'use strict';
import openPositionsByDayByGroupMutation from '../../graphql/queries/gekko/openPositionsByDayByGroup.gql';

import IndexChart from '../performance/IndexChart.js';

import auth from '../../auth';
export default {
  name: 'OpenPositionsGraph',
  components: {
    IndexChart,
  },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isLoadingGraph: false,

      openPositionsChartData: null,
      openPositionsChartOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      },

      currentDate:
        process.env.NODE_ENV === 'development'
          ? '2020-07-04'
          : this.$moment().format('YYYY-MM-DD'),
    };
  },

  //
  mounted() {
    if (auth.user.authenticated) {
      this.updateOpenPositionsCount();
    }
  },

  created() {
    this.demandLogin();
  },

  methods: {
    updateOpenPositionsCount() {
      this.isLoadingGraph = true;

      const variables = {
        input: {
          groupId: this.groupId,
          from: this.$moment(this.currentDate)
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
          to: this.currentDate,
        },
      };

      this.$apollo
        .mutate({
          mutation: openPositionsByDayByGroupMutation,
          variables,
          update: (store, { data: { openPositionsByDayByGroup } }) => {
            const {
              openPositionsCountLabels,
              openPositionsCountData,
            } = openPositionsByDayByGroup.reduce(
              (result, dailyPositions) => {
                result.openPositionsCountLabels.push(dailyPositions.date);
                result.openPositionsCountData.push(
                  dailyPositions.positionCount
                );

                return result;
              },
              { openPositionsCountLabels: [], openPositionsCountData: [] }
            );

            this.openPositionsChartData = {
              labels: openPositionsCountLabels,
              datasets: [
                {
                  label: 'Number of positions',
                  borderColor: '#fbb133',
                  fill: false,
                  data: openPositionsCountData,
                },
              ],
            };

            //
            this.isLoadingGraph = false;
          },
        })
        .catch((error) => {
          console.error('Dashboard.vue: updateOpenPositionsCount', error);
          this.isLoadingGraph = false;
          this.$buefy.toast.open({
            message: 'Error updating data',
            type: 'is-danger',
          });
        });
    },
  },
};
</script>
