//===================================================================
'use strict';
//===================================================================

import decode   from 'jwt-decode';

import router   from '../router';

//===================================================================

function userDataFromToken()
{
    try {
        const idToken = localStorage.getItem('id_token');
        const decodedToken = decode( idToken );

        return {
            id: decodedToken.id || null,
            username: decodedToken.username || null,
        };
    } catch(e) {
        return null;
    }
}

export default {
    user:
    {
        authenticated: false,
        id: userDataFromToken() ? userDataFromToken().id : null,
        username: userDataFromToken() ? userDataFromToken().username : null,
    },
    //
    login(context, creds, redirect)
    {
        return context.axios.post('/auth/login', creds)
        .then( res =>
        {
            if (!res.data.id_token || !res.data.access_token) throw 'No tokens';

            localStorage.setItem('id_token', res.data.id_token);
            localStorage.setItem('access_token', res.data.access_token);

            const userData = userDataFromToken();

            this.user.authenticated = true;
            this.user.id = userData.id;
            this.user.username = userData.username;

            router.push(redirect);
        })
        .catch( error => Promise.reject(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Network error.') );
    },
    //
    logout()
    {
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');

        this.user.authenticated = false;
        this.user.username = null;
    },
    //
    /*updatePassword(context)
    {
        return context.axios({
            method: 'post',
            url: context.token ? '/account/updatePasswordWithToken' : '/account/updatePassword',
            headers: !context.token ? this.getAuthHeader() : undefined,
            data: {
                //old_password: context.credentials.old_password,
                new_password: context.credentials.new_password,
                token: context.token || undefined,
            }
        });
    },*/
    //
    /*requestPasswordReset(context)
    {
        return context.axios.post('/account/requestPasswordReset',
        {
            username: context.credentials.username,
        });
    },
    verifyResetToken(context)
    {
        return context.axios.post('/account/verifyResetToken',
        {
            token: context.token,
        });
    },*/
    //
    checkAuth()
    {
        const idToken = localStorage.getItem('id_token');
        const accessToken = localStorage.getItem('access_token');
        if (!idToken || !accessToken) return;
        if (idToken==='undefined' || accessToken==='undefined') return;
        this.user.authenticated = !!idToken;
        if (!this.user.authenticated) return;

        const decodedToken = decode( idToken );
        if ( Math.floor(Date.now() / 1000) > decodedToken.exp )
        {
            this.user.authenticated = false;
        }
    },
    //
    getAuthToken()
    {
        return localStorage.getItem('access_token');
    },
    getAuthHeader()
    {
        const accessToken = this.getAuthToken();
        return {
            Authorization: accessToken ? `Bearer ${accessToken}` : null
        };
    }
};
