<!-- ============================================================ -->
<template>
    <div id="app">
        <app-header></app-header>
        <div id="main" class="container">
            <router-view></router-view>
        </div>
        <app-footer></app-footer>
    </div>
</template>


<!-- ============================================================ -->
<script>
'use strict';
//
//import auth from '../auth';
//
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
//
export default {
    name: 'app',
    //
    components : { AppHeader, AppFooter },
    //
    methods: 
    {
        //logout() { auth.logout(); },
    }
};
</script>
