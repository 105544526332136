<!-- ============================================================ -->
<template>
  <main>
    <template v-if="exchanges">
      <section>
        <b-tabs type="is-boxed" v-model="selectedExchangeTabIndex">
          <template v-for="exchange in exchanges">
            <b-tab-item
              v-bind:label="exchange.displayName"
              v-bind:icon="exchange.icon"
              :key="exchange.id"
            ></b-tab-item>
          </template>
        </b-tabs>
      </section>

      <div class="columns">
        <div class="column is-half">
          <b-field grouped>
            <p class="control">
              <span class="button is-static" style="width: 130px;"
                >Show last:</span
              >
            </p>

            <b-select v-model="sliderRangeType">
              <option value="day">24 hours</option>
              <option value="week">7 days</option>
              <option value="month">30 days</option>
            </b-select>
          </b-field>

          <b-field
            v-if="sliderRangeType === 'week'"
            style="margin-bottom: 17px;"
          >
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="1"
              type="is-info"
            >
              <span>6 days ago</span>
            </b-radio-button>
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="2"
              type="is-info"
            >
              <span>5 days ago</span>
            </b-radio-button>
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="3"
              type="is-info"
            >
              <span>4 days ago</span>
            </b-radio-button>
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="4"
              type="is-info"
            >
              <span>3 days ago</span>
            </b-radio-button>
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="5"
              type="is-info"
            >
              <span>2 days ago</span>
            </b-radio-button>
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="6"
              type="is-info"
            >
              <span>Yesterday</span>
            </b-radio-button>
            <b-radio-button
              v-model="sliderLastLocationWeek"
              native-value="7"
              type="is-info"
            >
              <span>Today</span>
            </b-radio-button>
          </b-field>

          <input
            v-else
            id="timeSlider"
            class="slider is-fullwidth is-large"
            v-bind:class="sliderType[sliderRangeType]"
            step="1"
            min="1"
            v-bind:max="sliderMax[sliderRangeType]"
            :value="sliderLastLocation[sliderRangeType]"
            type="range"
            @change="sliderDropped"
            v-on:input="sliderDragged"
          />

          <h3 class="subtitle is-3">{{ slideShownDateOrTime }}</h3>

          <div class="field">
            <b-switch size="is-small" v-model="limitValue">
              Show only products with value over $10
            </b-switch>
          </div>
        </div>
        <div class="column"></div>
      </div>

      <hr />

      <div style="min-height: 200px; position: relative;">
        <div v-for="inventory in inventorySnapshots" v-bind:key="inventory.id">
          <h5 class="title is-5">{{ inventory.name }}</h5>
          <h5 class="subtitle is-6">
            Snapshot time:
            {{
              parseInt(inventory.snapshotTime) | moment('DD. MM. YYYY HH:mm:ss')
            }}
          </h5>

          <div class="columns">
            <div class="column is-half">
              <b-table
                :data="inventory.products"
                :striped="true"
                :narrowed="true"
                default-sort="code"
                default-sort-direction="asc"
              >
                <template slot-scope="props">
                  <template
                    v-if="
                      props.row.amount && (props.row.value > 10 || !limitValue)
                    "
                  >
                    <b-table-column
                      field="code"
                      label="Product"
                      width="150"
                      sortable
                    >
                      {{ props.row.code }}
                    </b-table-column>

                    <b-table-column field="amount" label="Amount" sortable>
                      {{ props.row.amount | formatAmount() }}
                    </b-table-column>

                    <b-table-column field="code" label="Price (USD)" sortable>
                      <span v-if="props.row.price">
                        {{ props.row.price | formatCurrency() }}
                      </span>
                      <b-tooltip
                        v-else
                        label="No price information found!"
                        type="is-warning"
                      >
                        <b-icon icon="alert" type="is-warning"></b-icon>
                      </b-tooltip>
                    </b-table-column>

                    <b-table-column field="usdValue" label="USD Value" sortable>
                      <span v-if="props.row.price">
                        {{ props.row.value | formatCurrency() }}
                      </span>
                      <b-tooltip
                        v-else
                        label="USD value can not be calculated!"
                        type="is-info"
                      >
                        <b-icon
                          icon="help-rhombus-outline"
                          type="is-info"
                        ></b-icon>
                      </b-tooltip>
                    </b-table-column>
                  </template>
                </template>

                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="currency-usd-off" size="is-large">
                        </b-icon>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <!--p>Inventory empty.</p-->
                    </div>
                  </section>
                </template>
              </b-table>

              <br />
              <span style="font-weight: bold;">
                Total USD value: {{ inventory.sum | formatCurrency() }}
              </span>
            </div>
            <div></div>
          </div>
          <hr />
        </div>

        <div v-if="inventorySnapshots.length === 0">
          <div class="columns">
            <div class="column is-half">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="currency-usd-off" size="is-large"> </b-icon>
                  <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                </p>
                <!--p>No inventory snapshots found.</p-->
              </div>
            </div>
          </div>
        </div>

        <b-loading :is-full-page="false" :active="isLoadingData"></b-loading>
      </div>
    </template>
    <div v-else style="height: 500px;">
      <b-loading :is-full-page="false" :active="true"></b-loading>
    </div>
  </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';

import gql from 'graphql-tag';

import exchangeUtils from '../../utilities/exchanges';
//
import exchangeListQuery from '../../graphql/queries/exchanges/exchangeList.gql';
import latestSnapshotsMutation from '../../graphql/queries/exchanges/latestSnapshots.gql';

export default {
  name: 'exchangeIndex',
  //
  computed: {
    currentId() {
      return this.$route.params.id;
    },
  },
  //
  data() {
    const data = {
      //user: auth.user,
      //
      isLoadingData: false,
      selectedExchangeTabIndex: 0,
      //
      sliderRangeType: 'day',
      sliderType: {
        day: 'is-primary',
        month: 'is-warning',
      },
      sliderMax: {
        day: '24',
        month: '31',
      },
      sliderLastLocation: {
        day: '24',
        month: '31',
      },
      sliderLastLocationWeek: '7',
      //
      slideShownLocation: 24,
      slideShownDateOrTime: '',
      limitValue: false,
      //
      inventorySnapshots: [],
    };
    //
    return data;
  },
  //
  apollo: {
    exchanges() {
      return {
        query: gql`
          ${exchangeListQuery}
        `,
        update: null,
        result(result) {
          this.exchanges = exchangeUtils.prepareForTabs(this.exchanges);
          if (!this.currentId) {
            this.$router.push({
              name: 'exchange',
              params: { id: this.exchanges[0].id },
            });
          } else {
            this.selectedExchangeTabIndex = this.exchanges.findIndex(
              (exchange) => exchange.id == this.currentId
            );
          }

          this.showSelectedDateOrTime();
          this.loadSnapshots();
        },
      };
    },
  },
  //
  methods: {
    loadSnapshots() {
      this.isLoadingData = true;

      const beforeTime = this.getBeforeTime();

      const variables = {
        input: {
          exchangeId: this.exchanges[this.selectedExchangeTabIndex].id,
          beforeTime,
        },
      };

      this.$apollo
        .mutate({
          mutation: latestSnapshotsMutation,
          variables,
          update: (store, { data: { latestSnapshotsForExchange } }) => {
            this.inventorySnapshots = latestSnapshotsForExchange;

            this.inventorySnapshots.forEach((inventory) => {
              inventory.sum = inventory.products.reduce(
                (accumulator, product) => {
                  product.value = product.amount * product.price;
                  //
                  accumulator = accumulator + product.value;
                  return accumulator;
                },
                0
              );
            });

            this.isLoadingData = false;
          },
        })
        .catch((error) => {
          //console.log(error);
          this.isLoadingData = false;
          this.$buefy.toast.open({
            message: 'Error',
            type: 'is-danger',
          });
        });
    },
    //
    sliderDropped() {
      this.sliderLastLocation[this.sliderRangeType] =
        timeSlider.value || this.sliderMax[this.sliderRangeType];
      this.showSelectedDateOrTime();
      this.loadSnapshots();
    },
    sliderDragged() {
      this.slideShownLocation = timeSlider.value;
      this.showSelectedDateOrTime();
    },
    getBeforeTime() {
      if (this.sliderRangeType == 'week') {
        return this.$moment()
          .subtract(7 - this.sliderLastLocationWeek, 'days')
          .startOf('day')
          .format(this.$moment.defaultFormat);
      } else {
        return this.$moment()
          .subtract(
            this.sliderMax[this.sliderRangeType] - this.slideShownLocation,
            this.sliderRangeType === 'month' ? 'days' : 'hours'
          )
          .startOf(this.sliderRangeType === 'month' ? 'day' : 'hour')
          .format(this.$moment.defaultFormat);
      }
    },
    showSelectedDateOrTime() {
      if (this.sliderRangeType == 'week') {
        const unitsBeforeNow = 7 - this.sliderLastLocationWeek;
        this.slideShownDateOrTime = this.$moment()
          .subtract(unitsBeforeNow, 'days')
          .format('DD.MM.YYYY');
      } else {
        const unitsBeforeNow =
          this.sliderMax[this.sliderRangeType] - this.slideShownLocation;
        if (this.sliderRangeType === 'month') {
          this.slideShownDateOrTime = this.$moment()
            .subtract(unitsBeforeNow, 'days')
            .format('DD.MM.YYYY');
        } else if (this.sliderRangeType === 'day') {
          this.slideShownDateOrTime = this.$moment()
            .subtract(unitsBeforeNow, 'hours')
            .format('dddd HH:[00]');
        }
      }
    },
  },
  watch: {
    selectedExchangeTabIndex() {
      const exchangeId = this.exchanges[this.selectedExchangeTabIndex].id;
      this.$router.push({ name: 'exchange', params: { id: exchangeId } });

      this.loadSnapshots();
    },
    sliderRangeType() {
      this.slideShownLocation = this.sliderLastLocation[this.sliderRangeType];
      this.showSelectedDateOrTime();
      this.loadSnapshots();
    },
    sliderLastLocationWeek() {
      this.showSelectedDateOrTime();
      this.loadSnapshots();
    },
  },
  //
  created() {
    this.demandLogin();
  },
};
</script>
