//===================================================================
'use strict';
//===================================================================

import _ from 'lodash';

//===================================================================

export default {
    prepareForTabs(exchanges)
    {
        const manualSortAndIcons = {
            'kraken': {
                tab: 1,
                displayName: 'Kraken',
                icon: 'skull',
            },
            'binance': {
                tab: 2,
                displayName: 'Binance',
                icon: 'google-nearby',
            },
            'bittrex': {
                tab: 3,
                displayName: 'Bittrex',
                icon: 'checkbox-multiple-blank-outline',
            },
            'livecoin': {
                tab: 4,
                displayName: 'LiveCoin',
                icon: 'signal-cellular-3',
            },
            'hitbtc': {
                tab: 5,
                displayName: 'HitBtc',
                icon: 'target',
            },
            'tokens': {
                tab: 6,
                displayName: 'Tokens',
                icon: 'poll-box',
            },
            'mew': {
                tab: 7,
                displayName: 'Mew',
                icon: 'chart-donut-variant',
                //icon: 'pokemon-go',
            },
            'ico-virtual': {
                hidden: true,
            },
        };

        exchanges.sort( (e1, e2) =>
        {
            const e1val = manualSortAndIcons[e1.name] ? manualSortAndIcons[e1.name].tab : 100;
            const e2val = manualSortAndIcons[e2.name] ? manualSortAndIcons[e2.name].tab : 200;

            return e1val - e2val;
        });

        exchanges = exchanges.map(exchange =>
        {
            if (manualSortAndIcons[exchange.name].hidden) return null;
            if (manualSortAndIcons[exchange.name]) {
                exchange.displayName = manualSortAndIcons[exchange.name].displayName;
                exchange.icon = manualSortAndIcons[exchange.name].icon;
            } else {
                exchange.displayName = _.capitalize(exchange.name);
                exchange.icon = 'scale-balance';
            }

            return exchange;
        })
        .filter(exchange => exchange);

        return exchanges;
    }
};
