<template>
  <b-notification type="is-info" has-icon :active.sync="shouldShowPopup">
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <div class="tile is-child is-5">
          <p class="has-text-weight-bold is-capitalized">STARTED AT</p>
          <p>{{ startedAt | moment('D.M.YYY MM:HH') }}</p>
        </div>
        <div class="tile is-child is-5">
          <p class="has-text-weight-bold is-capitalized">last update</p>
          <p>{{ lastUpdate | moment('D.M.YYY MM:HH') }}</p>
        </div>
        <div class="tile is-child is-6">
          <p class="has-text-weight-bold is-capitalized">duration</p>
          <p>
            {{ lastUpdate | moment('diff', startedAt) | duration('humanize') }}
          </p>
        </div>
      </div>
    </div>
  </b-notification>
</template>

<script>
'use strict';
export default {
  name: 'GekkoGroupDuration',
  props: {
    startedAt: {
      type: String,
      default: null,
    },
    lastUpdate: {
      type: String,
      default: null,
    },
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      shouldShowPopup: false,
    };
  },
  methods: {
    setShowPopup(showPopup) {
      this.shouldShowPopup = showPopup;
    },
  },
};
</script>
