<!-- ============================================================ -->
<template>
    <div class="columns is-gapless is-multiline" style="margin-bottom:8px">
        <div class="column is-one-third">
            <b-tooltip v-bind:label="change.name" dashed>
                <strong>{{ change.code }}</strong>
            </b-tooltip>
            <!--b-icon v-if="change.toPrice - change.fromPrice < 0" icon="arrow-down-bold" type="is-danger"></b-icon>
            <b-icon v-else icon="arrow-up-bold" type="is-success"></b-icon-->
            &nbsp;
            {{ change.toPrice - change.fromPrice | formatCurrency() }}
        </div>
        <div class="column is-one-third">
            <!--b-icon v-if="change.toValue - change.fromValue < 0" icon="arrow-down-bold" type="is-danger"></b-icon>
            <b-icon v-else icon="arrow-up-bold" type="is-success"></b-icon-->
            {{ change.toValue - change.fromValue | formatCurrency() }}
        </div>
        <div class="column is-one-third">
            {{ change.toAmount - change.fromAmount | formatAmount(4) }}
        </div>

        <div class="column is-one-third">
            {{ change.fromPrice | formatCurrency() }}
            <b-icon v-if="change.toPrice - change.fromPrice < 0" icon="arrow-bottom-right-thick" type="is-danger" size="is-small"></b-icon>
            <b-icon v-else-if="change.toPrice - change.fromPrice == 0" icon="arrow-right-thick" type="is-info" size="is-small"></b-icon>
            <b-icon v-else icon="arrow-top-right-thick" type="is-success" size="is-small"></b-icon>
            {{ change.toPrice | formatCurrency()  }}
            <div class="columns is-gapless">
                <div :class="linePriceClass" :style="linePriceStyle">
                </div>
                <div class="column">
                </div>
            </div>
        </div>
        <div class="column is-one-third">
            {{ change.fromValue | formatCurrency() }}
            <b-icon v-if="change.toValue - change.fromValue < 0" icon="arrow-bottom-right-thick" type="is-danger" size="is-small"></b-icon>
            <b-icon v-else-if="change.toValue - change.fromValue < 0" icon="arrow-right-thick" type="is-info" size="is-small"></b-icon>
            <b-icon v-else icon="arrow-top-right-thick" type="is-success" size="is-small"></b-icon>
            {{ change.toValue | formatCurrency() }}
            <div class="columns is-gapless">
                <div :class="lineValueClass" :style="lineValueStyle">
                </div>
                <div class="column">
                </div>
            </div>
        </div>
        <div class="column is-one-third">
            {{ change.fromAmount | formatAmount(4) }}
            <b-icon v-if="change.toAmount - change.fromAmount < 0" icon="arrow-bottom-right-thick" type="is-danger" size="is-small"></b-icon>
            <b-icon v-else-if="change.toAmount - change.fromAmount == 0" icon="arrow-right-thick" type="is-info" size="is-small"></b-icon>
            <b-icon v-else icon="arrow-top-right-thick" type="is-success" size="is-small"></b-icon>
            {{ change.toAmount | formatAmount(4) }}
        </div>

    </div>
</template>

<!-- ============================================================ -->
<script>
'use strict';

export default {
    name : 'coinChange',
    //
    props: {
        change: { type: Object, required: true },
    },
    //
    computed: {
        linePriceClass() {
            return this.lineLengthClass(this.change.toPrice, this.change.fromPrice);
        },
        linePriceStyle() {
            return `background-color:${ this.lineColor(this.change.toPrice, this.change.fromPrice) }; height:8px; margin-top:8px; margin-bottom:8px`;
        },
        lineValueClass() {
            return this.lineLengthClass(this.change.toValue, this.change.fromValue);
        },
        lineValueStyle() {
            return `background-color:${ this.lineColor(this.change.toValue, this.change.fromValue) }; height:8px; margin-top:8px; margin-bottom:8px`;
        }
    },
    //
    methods: {
        lineLengthClass(to, from) {
            if ( Math.abs(to - from) < 0.01 ) return '';
            let length = Math.abs(Math.round((( 100 * ( to - from ) ) / to ) / 10));
            if (!length) return '';
            if (length > 12) length = 12;
            return `column is-${length}`;
        },
        lineColor(to, from) {
            return ( to - from ) > 0 ? '#23d160' : '#ff3860';
        },
    },
};

</script>
