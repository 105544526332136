<!-- ============================================================ -->
<template>
  <b-tabs v-model="activeTab">
    <b-tab-item label="Running">
      <instance-list
        v-bind:is-running="true"
        v-bind:is-assigned-to-group="null"
        ref="instanceListRunning"
      />
    </b-tab-item>

    <b-tab-item label="Un-assigned">
      <instance-list
        v-bind:is-assigned-to-group="false"
        v-bind:is-running="null"
        ref="instanceListUnassigned"
      />
    </b-tab-item>
  </b-tabs>
</template>

<!-- ============================================================ -->
<script>
'use strict';
import InstanceList from './InstanceList.vue';
//
import auth from '../../auth';

export default {
  name: 'RunningGekkoInstances',
  components: {
    InstanceList,
  },

  data() {
    return {
      activeTab: 0,
    };
  },

  watch: {
    activeTab: function (newTab) {
      if (newTab == 1) {
        this.loadUnasignedInstanceList();
      } else {
        this.loadRunningInstanceList();
      }
    },
  },

  //
  mounted() {
    if (auth.user.authenticated) {
      this.loadRunningInstanceList();
    }
  },

  //
  created() {
    this.demandLogin();
  },
  apollo: {},
  methods: {
    loadRunningInstanceList() {
      this.$refs.instanceListRunning.getInstanceList();
    },
    loadUnasignedInstanceList() {
      this.$refs.instanceListUnassigned.getInstanceList();
    },
  },
};
</script>
