<!-- ============================================================ -->
<template>
    <main>

        <article class="message is-info">
            <div class="message-body">
                Wallet support coming soon.
            </div>
        </article>

        <img src="/static/wallet.jpg" style="height:170px">

    </main>
</template>

<!-- ============================================================ -->
<script>
'use strict';

export default {
    name : 'wallets',
    //
    data()
    {
        const data =
        {
            //
        };
        //
        return data;
    },
    //
    methods:
    {
        //
    },
    watch:
    {
        //
    },
    //
    created()
    {
        this.demandLogin();
    },
    mounted()
    {
        //
    }
};
</script>
